/*---------------------------------------------
Template Name: Cirkle Social Media Community HTML Template
Template URL: http://www.radiustheme.com/demo/html/cirkle/
Description: Social Media and Community HTML Template
Author: RadiusTheme
Author URL: http://www.radiustheme.com 
Version: 1.0.0

===================   
CSS INDEX
===================
1. Typography
2. Bootstrap Overwrite
	2.1 Form
    2.2 Grid
3. Vendor Custom Style
    3.1 Slick
    3.2 Select 2
4. Template Layout Style
	4.1 Back To Top
	4.2 Breadcrumb Banner
    4.3 Footer
    4.4 Global
    4.5 Helper Classes
    4.6 Header Menu
    4.7 Pagination
    4.8 Preloader
    4.9 Section
5. Template Page Style
	5.1 About
	5.2 Banner
	5.3 Blog
	5.4 Community
	5.5 Contact Us
	5.6 Groups
	5.7 Location
	5.8 Team
	5.9 Testimonial
	5.10 Why Choose Us
	5.11 Login Page
6.  Dashboard
	6.1 Banner
	6.2 Blocks
	6.3 Forum
	6.4 Post
	6.5 Product
	6.6 Search
	6.7 User Activity
	6.8 Widget 
--------------------------------------------*/
/*=======================================================================
1. Typography 
=========================================================================*/
body {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: #646464;
    font-weight: 400;
    height: 100%;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-color: #ffffff;
}

p {
    margin: 0 0 10px 0;
    color: #646464;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito", sans-serif;
    margin: 0 0 10px 0;
    font-weight: 700;
    line-height: 28px;
    color: #000000;
}

h1 {
    font-size: 46px;
    line-height: 52px;
}

@media only screen and (max-width: 1199px) {
    h1 {
        font-size: 44px;
    }
}

@media only screen and (max-width: 991px) {
    h1 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 34px;
    }
}

@media only screen and (max-width: 575px) {
    h1 {
        font-size: 30px;
    }
}

h2 {
    font-size: 36px;
    line-height: 42px;
}

@media only screen and (max-width: 1199px) {
    h2 {
        font-size: 34px;
    }
}

@media only screen and (max-width: 991px) {
    h2 {
        font-size: 32px;
    }
}

@media only screen and (max-width: 767px) {
    h2 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 24px;
    }
}

h3 {
    font-size: 28px;
}

@media only screen and (max-width: 1199px) {
    h3 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 991px) {
    h3 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    h3 {
        font-size: 22px;
    }
}

h4 {
    font-size: 22px;
}

@media only screen and (max-width: 575px) {
    h4 {
        font-size: 20px;
    }
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 14px;
}

/*=======================================================================
2. Bootstrap Overwrite
=========================================================================*/
/*------------------- 2.1 Form -------------------*/
.form-group {
    position: relative;
    margin-bottom: 20px;
}

.form-group .form-control {
    color: #111111;
    padding: 20px;
    border-color: #d7d7d7;
    background-color: #ffffff;
    border-radius: 4px;
}

.form-group .form-control:focus {
    outline: none;
    box-shadow: none;
}

.form-group input {
    height: 50px;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-group .submit-btn {
    height: auto;
    border: none;
    border-radius: 4px;
    font-weight: 700;
    color: #ffffff;
    font-size: 14px;
    padding: 8px 25px;
    background-color: #615dfa;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.form-group .submit-btn:hover {
    background-color: #5edfff;
}

.form-group .submit-btn:focus {
    outline: none;
}

.form-group input.form-control::-webkit-input-placeholder,
  .form-group textarea.form-control::-webkit-input-placeholder {
    color: #646464;
}

.input-group {
    border-radius: 30px;
    background-color: #ffffff;
    border: none;
}

.input-group .form-control {
    border-radius: 4px 0 0 4px;
    padding: 0 20px;
    height: 40px;
    font-size: 12px;
    color: #111111;
    border: none;
}

.input-group .form-control:focus {
    box-shadow: none;
}

.input-group .input-group-append .search-btn {
    border: none;
    background-color: #615dfa;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 0 4px 4px 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.input-group .input-group-append .search-btn:focus {
    outline: none;
}

.input-group .input-group-append .search-btn:hover {
    background-color: #5edfff;
}

.input-group input.form-control::-webkit-input-placeholder,
  .input-group textarea.form-control::-webkit-input-placeholder {
    color: #969696;
}

.form-check {
    position: relative;
    padding-left: 0;
}

.form-check label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    color: #646464;
    cursor: pointer;
}

.form-check label i {
    color: #ffbc09;
    margin-right: 4px;
}

.form-check label:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    top: 5px;
    background-color: transparent;
    border: 2px solid #b1b0b0;
    border-radius: 2px;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.form-check label:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 0;
    font-size: 16px;
}

.form-check input[type="checkbox"] {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 4px;
    left: 20px;
    z-index: 1;
    opacity: 0;
    margin-top: 0;
}

.form-check input[type="checkbox"]:checked + label {
    color: #000000;
}

.form-check input[type="checkbox"]:checked + label:after {
    font-family: IcoFont;
    content: "\eed8";
    font-weight: 600;
}

.form-check input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}

.form-check input[type="checkbox"]:disabled + label:before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

.form-check label::before {
    border-radius: 4px;
}

.form-check input[type="checkbox"]:checked + label:before {
    background-color: #615dfa;
    border-color: #615dfa;
}

.form-check input[type="checkbox"]:checked + label:after {
    color: #ffffff;
}

/*------------------- 2.2 Grid -------------------*/
@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.container-fluid.full-width {
    padding-left: 0;
    padding-right: 0;
}

.container-fluid.full-width > .row {
    margin-left: 0;
    margin-right: 0;
}

.container-fluid.full-width > .row > [class^="col-"],
    .container-fluid.full-width > .row > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

.row.gutters-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.row.gutters-10 > [class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.row.gutters-15 {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.row.gutters-15 > [class^="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.row.gutters-20 {
    padding-left: 5px;
    padding-right: 5px;
}

.row.gutters-20 > [class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}

/*=======================================================================
3. Vendor Custom Style
=========================================================================*/
/*------------------- 3.1 Slick -------------------*/
.slick-slide:focus {
    outline: none;
}

.slick-arrow {
    visibility: hidden;
    opacity: 0;
    height: 35px;
    width: 35px;
    line-height: 48px;
    background-color: white;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 5;
}

.slick-arrow:before {
    font-family: "Font Awesome 5 Free";
    color: #615dfa;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-arrow:hover {
    background-color: white;
}

.slick-prev {
    left: 30px;
}

.slick-prev:before {
    content: "\f104";
}

.slick-next {
    right: 30px;
}

.slick-next:before {
    content: "\f105";
}

.slick-dots {
    left: 0;
    bottom: -35px;
    width: auto;
}

.slick-dots li {
    margin: 0 8px 0 0;
    height: 13px;
    width: 13px;
    border-radius: 50%;
}

.slick-dots li button {
    background-color: #cccccc;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-dots li button:before {
    display: none;
}

.slick-dots li.slick-active button {
    background-color: #615dfa;
}

/*------------------- 3.2 Select 2 -------------------*/
.select2-container {
    min-width: 250px;
}

.select2-container--classic .select2-selection--single {
    background: #ffffff;
    border: 1px solid #eaeaea;
    height: 40px;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
    color: #646464;
    padding-left: 20px;
    font-size: 14px;
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #eaeaea;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background: none;
    border-left: none;
    height: 40px;
    top: 0;
    right: 4px;
    width: 40px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #646464 transparent transparent transparent;
    border-width: 6px 5px 0 5px;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #646464 transparent;
    border-width: 0 5px 6px 5px;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    background: #ffffff;
    border-color: #eaeaea;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-color: #eaeaea;
    background: transparent;
    border-top: 1px solid #eaeaea;
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #eaeaea;
    position: inherit !important;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #615dfa;
}

.select2-results__option {
    padding: 5px 20px;
    font-size: 14px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #646464;
}

/*=======================================================================
4. Template Layout Style
=========================================================================*/
/*------------------- 4.1 Back To Top -------------------*/
.scrollup {
    overflow: hidden;
    position: fixed;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    bottom: -100px;
    right: 20px;
    text-align: center;
    z-index: 9999;
    background-color: #615dfa;
    color: #ffffff;
    font-size: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.8s cubic-bezier(0, 0, 0.15, 1.88);
    transition: all 0.8s cubic-bezier(0, 0, 0.15, 1.88);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.scrollup:hover {
    color: #615dfa;
    background-color: #ffffff;
}

.scrollup:focus {
    color: #ffffff;
}

.scrollup.back-top {
    bottom: 25px;
}

/*------------------- 4.2 Breadcrumb Banner -------------------*/
.breadcrumbs-banner {
    margin-top: -97px;
    padding: 185px 0 95px;
    background-color: #615dfa;
    background-image: url("../../media/banner/hero_1.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 1199px) {
    .breadcrumbs-banner {
        padding: 165px 0 75px;
    }
}

@media only screen and (max-width: 1199px) {
    .breadcrumbs-banner {
        padding: 145px 0 55px;
    }
}

.breadcrumbs-banner .breadcrumb-animate-img {
    position: absolute;
    right: 9%;
    bottom: 0;
    line-height: 0;
    z-index: -1;
}

@media only screen and (max-width: 1199px) {
    .breadcrumbs-banner .breadcrumb-animate-img {
        right: -10%;
        bottom: -10%;
    }

    .breadcrumbs-banner .breadcrumb-animate-img img {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 991px) {
    .breadcrumbs-banner .breadcrumb-animate-img {
        right: -20%;
        bottom: -25%;
    }

    .breadcrumbs-banner .breadcrumb-animate-img img {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
    }
}

.breadcrumbs-banner .breadcrumbs-area {
    position: relative;
    z-index: 1;
}

.breadcrumbs-banner .breadcrumbs-area h1 {
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 6px;
}

.breadcrumbs-banner .breadcrumbs-area ul li {
    display: inline-block;
    color: #ffffff;
    position: relative;
    margin-right: 20px;
    font-size: 14px;
}

.breadcrumbs-banner .breadcrumbs-area ul li:after {
    content: "/";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -14px;
}

.breadcrumbs-banner .breadcrumbs-area ul li a {
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.breadcrumbs-banner .breadcrumbs-area ul li a:hover {
    color: #5edfff;
}

.breadcrumbs-banner .breadcrumbs-area ul li:last-child {
    margin-right: 0;
}

.breadcrumbs-banner .breadcrumbs-area ul li:last-child:after {
    display: none;
}

/*------------------- 4.3 Footer -------------------*/
.footer-wrap {
    background-color: #f0f4f6;
    position: relative;
    z-index: 1;
}

.footer-wrap:before {
    content: url("../../media/figure/footer_shape.png");
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
    overflow: hidden;
    line-height: 0;
}

@media only screen and (max-width: 479px) {
    .footer-wrap .footer-top-image {
        display: none;
    }
}

.footer-wrap .footer-top-image li {
    display: inline-block;
    position: absolute;
    top: -103px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -2;
}

.footer-wrap .footer-top-image li:nth-child(1n) {
    left: 50%;
}

.footer-wrap .footer-top-image li:nth-child(2n) {
    left: calc(50% + 135px);
    top: -100px;
}

.footer-wrap .footer-top-image li:nth-child(3n) {
    left: calc(50% - 135px);
    top: -97px;
}

.footer-wrap .footer-top-image li:nth-child(4n) {
    left: calc(50% + 270px);
    top: -97px;
}

@media only screen and (max-width: 767px) {
    .footer-wrap .footer-top-image li:nth-child(4n) {
        display: none;
    }
}

.footer-wrap .footer-top-image li:nth-child(5n) {
    left: calc(50% - 270px);
    top: -88px;
}

@media only screen and (max-width: 767px) {
    .footer-wrap .footer-top-image li:nth-child(5n) {
        display: none;
    }
}

.footer-wrap .footer-top-image li:nth-child(6n) {
    left: calc(50% + 405px);
    top: -80px;
}

@media only screen and (max-width: 991px) {
    .footer-wrap .footer-top-image li:nth-child(6n) {
        display: none;
    }
}

.footer-wrap .footer-top-image li:nth-child(7n) {
    left: calc(50% - 405px);
    top: -69px;
}

@media only screen and (max-width: 991px) {
    .footer-wrap .footer-top-image li:nth-child(7n) {
        display: none;
    }
}

.footer-wrap .footer-top-image li:nth-child(8n) {
    left: calc(50% + 540px);
    top: -60px;
}

@media only screen and (max-width: 1199px) {
    .footer-wrap .footer-top-image li:nth-child(8n) {
        display: none;
    }
}

.footer-wrap .footer-top-image li:nth-child(9n) {
    left: calc(50% - 540px);
    top: -44px;
}

@media only screen and (max-width: 1199px) {
    .footer-wrap .footer-top-image li:nth-child(9n) {
        display: none;
    }
}

.main-footer {
    padding: 140px 0 25px;
}

@media only screen and (max-width: 991px) {
    .main-footer {
        background-color: #ffffff;
        padding: 80px 0 25px;
    }
}

.footer-box {
    margin-bottom: 50px;
}

.footer-box .footer-logo {
    margin-bottom: 20px;
}

.footer-box .footer-title {
    font-size: 22px;
    padding-bottom: 18px;
    margin-bottom: 22px;
    position: relative;
}

.footer-box .footer-title:after {
    content: "";
    height: 3px;
    width: 20px;
    background-color: #615dfa;
    position: absolute;
    bottom: 0;
    left: 8px;
}

.footer-box .footer-title:before {
    content: "";
    height: 3px;
    width: 6px;
    background-color: #615dfa;
    position: absolute;
    bottom: 0;
    left: 0;
}

.footer-box .footer-link ul li {
    margin-bottom: 7px;
}

.footer-box .footer-link ul li:last-child {
    margin-bottom: 0;
}

.footer-box .footer-link ul li a {
    color: #646464;
}

.footer-box .footer-link ul li a:hover {
    color: #615dfa;
}

.footer-bottom {
    padding: 30px 0;
    border-top: 1px solid #ececec;
    text-align: center;
}

.footer-bottom .footer-copyright {
    color: #838383;
}

.footer-dashboard {
    background-color: #ffffff;
    margin-top: 60px;
}

@media only screen and (max-width: 767px) {
    .footer-dashboard {
        margin-top: 40px;
    }
}

.footer-dashboard:before {
    display: none;
}

.footer-dashboard .main-footer {
    padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
    .footer-dashboard .main-footer {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 991px) {
    .footer-dashboard .main-footer {
        padding-top: 80px;
    }
}

/*------------------- 4.4 Global -------------------*/
a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:active,
a:hover,
a:focus {
    text-decoration: none;
    color: #111111;
    outline: 0 none;
}

button:active,
button:hover,
button:focus {
    outline: 0 none;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

hr {
    margin: 0;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    margin-left: 0;
}

.item-icon {
    line-height: 1;
}

.item-icon i {
    display: inline-block;
}

/*------------------- 4.5 Helper Classes -------------------*/
.button-slide {
    color: #000000;
    border: 1px solid #d7d7d7;
    border-radius: 30px;
    padding: 4px 3px;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    z-index: 1;
}

.button-slide:after {
    content: "";
    height: 41px;
    width: 41px;
    background-color: #5edfff;
    border-radius: 30px;
    position: absolute;
    top: 49%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1;
}

.button-slide .btn-text {
    margin-left: 24px;
}

.button-slide .btn-icon {
    margin-left: 15px;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.button-slide .btn-icon svg {
    vertical-align: baseline;
}

.button-slide:hover:after {
    width: 95%;
    border-radius: 30px;
}

.bg-common {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-fb {
    background-color: #365493;
}

.bg-twitter {
    background-color: #38b8ff;
}

.bg-instagram {
    background-color: #ff3b6e;
}

.bg-pinterest {
    background-color: #cd0f40;
}

.bg-dribble {
    background-color: #ec407a;
}

.bg-youtube {
    background-color: #ff0000;
}

.bg-behance {
    background-color: #2962ff;
}

.color-fb {
    color: #1877f2;
}

.color-twitter {
    color: #1da1f2;
}

.color-instagram {
    color: #c32aa3;
}

.color-messenger {
    color: #00B2FF;
}

.color-whatsapp {
    color: #25d366;
}

.bg-primary {
    background-color: #615dfa !important;
}

.bg-aash {
    background-color: #f8f8f8;
}

.bg-link-water {
    background-color: #eff4fb;
}

.bg-salmon-gradient {
    background-image: -webkit-linear-gradient(left, #ff8a65 30%, #ff5722 100%);
    background-image: linear-gradient(to right, #ff8a65 30%, #ff5722 100%);
}

.bg-amethyst-gradient {
    background-image: -webkit-linear-gradient(left, #4035cd 30%, #ab47bc 100%);
    background-image: linear-gradient(to right, #4035cd 30%, #ab47bc 100%);
}

.bg-sun-gradient {
    background-image: -webkit-linear-gradient(left, #ffd740 30%, #ffcb40 100%);
    background-image: linear-gradient(to right, #ffd740 30%, #ffcb40 100%);
}

.bg-tagerine-gradient {
    background-image: -webkit-linear-gradient(left, #ff8b82 30%, #e53935 100%);
    background-image: linear-gradient(to right, #ff8b82 30%, #e53935 100%);
}

.bg-picton-gradient {
    background-image: -webkit-linear-gradient(left, #4dbdef 30%, #7c4dff 100%);
    background-image: linear-gradient(to right, #4dbdef 30%, #7c4dff 100%);
}

.bg-jungle-gradient {
    background-image: -webkit-linear-gradient(left, #26a69a 30%, #64dd17 100%);
    background-image: linear-gradient(to right, #26a69a 30%, #64dd17 100%);
}

.bg-dodger-gradient {
    background-image: -webkit-linear-gradient(left, #2962ff 30%, #00b0ff 100%);
    background-image: linear-gradient(to right, #2962ff 30%, #00b0ff 100%);
}

.bg-pink-gradient {
    background-image: -webkit-linear-gradient(left, #ef9a9a 30%, #f06292 100%);
    background-image: linear-gradient(to right, #ef9a9a 30%, #f06292 100%);
}

.bg-spring-gradient {
    background-image: -webkit-linear-gradient(left, #00e676 30%, #1de9b6 100%);
    background-image: linear-gradient(to right, #00e676 30%, #1de9b6 100%);
}

.bg-gray-gradient {
    background-image: -webkit-linear-gradient(left, #b0bec5 30%, #607d8b 100%);
    background-image: linear-gradient(to right, #b0bec5 30%, #607d8b 100%);
}

.tooltip-inner {
    font-size: 11px;
    font-weight: 600;
    padding: 5px 10px;
    background: #5edfff;
    color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(107, 220, 248, 0.7);
    z-index: 5;
}

.bs-tooltip-right .arrow::before {
    border-right-color: #5edfff;
}

.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #5edfff;
}

.bs-tooltip-top .arrow::before {
    border-top-color: #5edfff;
}

/* Keyframe Animation */
@-webkit-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }

    100% {
        margin-left: -1000px;
    }
}

@-webkit-keyframes movingleftright1 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(50px) translateY(-50px);
        transform: translateX(50px) translateY(-50px);
    }

    75% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@keyframes movingleftright1 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(50px) translateY(-50px);
        transform: translateX(50px) translateY(-50px);
    }

    75% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@-webkit-keyframes movingleftright2 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(-50px) translateY(50px);
        transform: translateX(-50px) translateY(50px);
    }

    75% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@keyframes movingleftright2 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(-50px) translateY(50px);
        transform: translateX(-50px) translateY(50px);
    }

    75% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@-webkit-keyframes movingleftright3 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(100px) translateY(-50px);
        transform: translateX(100px) translateY(-50px);
    }

    75% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@keyframes movingleftright3 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(100px) translateY(-50px);
        transform: translateX(100px) translateY(-50px);
    }

    75% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #0c1fd7;
    }

    70% {
        box-shadow: 0 0 0 20px rgba(12, 31, 215, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(12, 31, 215, 0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #0c1fd7;
    }

    70% {
        box-shadow: 0 0 0 20px rgba(12, 31, 215, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(12, 31, 215, 0);
    }
}

@-webkit-keyframes movinground {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }

    60% {
        -webkit-transform: rotate(180deg) scale(1.5);
        transform: rotate(180deg) scale(1.5);
    }

    80% {
        -webkit-transform: rotate(90deg) scale(0.6);
        transform: rotate(90deg) scale(0.6);
    }

    100% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }
}

@keyframes movinground {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }

    60% {
        -webkit-transform: rotate(180deg) scale(1.5);
        transform: rotate(180deg) scale(1.5);
    }

    80% {
        -webkit-transform: rotate(90deg) scale(0.6);
        transform: rotate(90deg) scale(0.6);
    }

    100% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }
}

@-webkit-keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 #5edfff;
    }

    70% {
        box-shadow: 0 0 0 20px rgba(94, 223, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(94, 223, 255, 0);
    }
}

@keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 #5edfff;
    }

    70% {
        box-shadow: 0 0 0 20px rgba(94, 223, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(94, 223, 255, 0);
    }
}

/*------------------- 4.6 Header Menu -------------------*/
header.header {
    position: relative;
    z-index: 15;
}

.temp-logo {
    position: relative;
}

@media only screen and (max-width: 991px) {
    .temp-logo {
        display: none;
    }
}

.temp-logo:before {
    content: "";
    height: 9px;
    width: 9px;
    background-color: #5edfff;
    position: absolute;
    top: 1px;
    left: 44px;
    border-radius: 50%;
    -webkit-animation: pulse2 linear 1000ms infinite;
    animation: pulse2 linear 1000ms infinite;
}

.header-menu {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-menu:after {
    clear: both;
    content: "";
    display: block;
}

nav.template-main-menu > ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

nav.template-main-menu > ul > li {
    position: relative;
}

nav.template-main-menu > ul > li > a {
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    display: block;
    color: #ffffff;
    padding: 40px 15px;
}

@media only screen and (max-width: 1199px) {
    nav.template-main-menu > ul > li > a {
        padding: 40px 10px;
    }
}

nav.template-main-menu > ul > li > a:hover {
    color: #5edfff;
}

nav.template-main-menu > ul > li a.have-sub {
    position: relative;
}

nav.template-main-menu > ul > li a.have-sub:after {
    content: "\eab2";
    font-family: IcoFont !important;
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    padding-left: 6px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li a.have-sub:hover:after {
    color: #5edfff;
}

nav.template-main-menu > ul > li ul.sub-menu {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    width: 230px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 85%;
    left: 0;
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px);
    pointer-events: none;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

nav.template-main-menu > ul > li ul.sub-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #ffffff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    top: -10px;
    left: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.sub-menu li {
    position: relative;
}

nav.template-main-menu > ul > li ul.sub-menu li a {
    overflow: hidden;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #444444;
    padding: 2px 0;
    display: block;
}

nav.template-main-menu > ul > li ul.sub-menu li a:after {
    content: "";
    height: 100%;
    width: 100%;
    background: -webkit-linear-gradient(bottom, #ff630e 0%, #ff000a 100%);
    background: linear-gradient(to top, #ff630e 0%, #ff000a 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.sub-menu li a:hover {
    color: #5edfff;
}

nav.template-main-menu > ul > li ul.mega-menu {
    padding: 10px 0;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 85%;
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

nav.template-main-menu > ul > li ul.mega-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #ffffff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    top: -10px;
    left: 150px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.mega-menu > li {
    width: 230px;
    display: inline-block;
    margin: 0 5px;
    float: left;
}

nav.template-main-menu > ul > li ul.mega-menu > li > ul.sub-menu {
    position: relative;
    width: 100%;
    border: none;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

nav.template-main-menu > ul > li ul.mega-menu > li > ul.sub-menu:before {
    display: none;
}

nav.template-main-menu > ul > li ul.mega-menu-col-2 {
    width: 480px;
    left: -100px;
    padding: 0;
}

nav.template-main-menu > ul > li:hover ul.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    pointer-events: auto;
}

nav.template-main-menu > ul > li:hover ul.mega-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    pointer-events: auto;
}

.menu-layout1 {
    padding: 0 35px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 1440px) {
    .menu-layout1 {
        padding: 0;
    }
}

.rt-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
    background-color: #615dfa;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -webkit-animation: upsdown 1000ms;
    animation: upsdown 1000ms;
}

.rt-sticky nav.template-main-menu > ul > li > a {
    padding: 30px 15px;
}

@media only screen and (max-width: 1199px) {
    .rt-sticky nav.template-main-menu > ul > li > a {
        padding: 30px 10px;
    }
}

@media only screen and (min-width: 992px) {
    .hide-on-desktop-menu {
        display: none !important;
    }
}

@media only screen and (max-width: 991px) {
    .hide-on-mobile-menu {
        display: none;
    }
}

.header-action ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-action ul li {
    margin-right: 26px;
}

.header-action ul li:last-child {
    margin-right: 0;
}

.header-action ul .header-social {
    position: relative;
    margin-right: 45px;
}

@media only screen and (max-width: 1199px) {
    .header-action ul .header-social {
        display: none;
    }
}

.header-action ul .header-social:after {
    content: "";
    height: 24px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 50%;
    right: -22px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-action ul .header-social a {
    color: #ffffff;
    padding: 5px;
    margin-right: 4px;
}

.header-action ul .header-social a:hover {
    color: #5edfff;
}

.header-action ul .header-social a:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 479px) {
    .header-action ul .header-search-icon {
        display: none;
    }
}

.header-action ul .header-search-icon a {
    color: #ffffff;
    font-size: 20px;
}

.header-action ul .header-search-icon a:hover {
    color: #5edfff;
}

.header-action ul .login-btn a {
    color: #000000;
    background-color: #ffffff;
    padding: 8px 25px;
    font-weight: 700;
    border-radius: 4px;
}

@media only screen and (max-width: 575px) {
    .header-action ul .login-btn a {
        padding: 6px 25px;
    }
}

.header-action ul .login-btn a:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.header-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
}

.header-search .header-search-form {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-search .header-search-form input[type="search"] {
    width: 60%;
    color: white;
    font-size: 24px;
    text-align: left;
    border: none;
    margin: 0 auto;
    padding: 25px 10px 25px 30px;
    outline: none;
    background: -webkit-linear-gradient(left, transparent, rgba(200, 200, 200, 0.5), transparent);
    background: linear-gradient(to right, transparent, rgba(200, 200, 200, 0.5), transparent);
}

@media only screen and (max-width: 575px) {
    .header-search .header-search-form input[type="search"] {
        width: 75%;
    }
}

.header-search .header-search-form .search-btn {
    padding: 24px 10px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.header-search .header-search-form .search-btn i:before {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-left: 0;
}

.header-search .header-search-form .search-btn:focus {
    box-shadow: none;
    outline: none;
}

.header-search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #111111;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    opacity: 1;
    visibility: visible;
    padding: 3px 15px 5px;
    font-size: 36px;
    font-weight: 300;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-search .close:focus {
    box-shadow: none;
    border: none;
    outline: none;
}

.header-search .close:hover {
    background-color: white;
}

.header-search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    visibility: visible;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.fixed-header .header-menu {
    padding: 0 90px !important;
    background-color: #615dfa;
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-menu {
        padding: 0 60px !important;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-header .header-menu {
        padding: 0 50px !important;
    }
}

.fixed-header .header-menu .header-logo {
    position: relative;
    margin-bottom: 8px;
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-menu .header-logo {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-menu .header-logo img {
        height: 30px;
    }
}

.fixed-header .header-menu .header-logo:before {
    content: "";
    height: 9px;
    width: 9px;
    background-color: #5edfff;
    position: absolute;
    top: 1px;
    left: 44px;
    border-radius: 50%;
    -webkit-animation: pulse2 linear 1000ms infinite;
    animation: pulse2 linear 1000ms infinite;
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-menu .header-logo:before {
        height: 6px;
        width: 6px;
        top: 1px;
        left: 31px;
    }
}

.fixed-header .navbar {
    padding: 0 40px;
}

@media only screen and (max-width: 1439px) {
    .fixed-header .navbar {
        padding: 0 25px;
    }
}

@media only screen and (max-width: 1360px) {
    .fixed-header .navbar {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1199px) {
    .fixed-header .navbar {
        padding: 19px 15px;
    }
}

@media only screen and (max-width: 991px) {
    .fixed-header .navbar {
        padding: 7px 10px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-header .navbar {
        padding: 2px 10px;
    }
}

@media only screen and (max-width: 575px) {
    .fixed-header .navbar {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 479px) {
    .fixed-header .navbar {
        padding: 2px 5px;
    }
}

.fixed-header .navbar .nav-top-menu {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 30px;
}

@media only screen and (max-width: 1199px) {
    .fixed-header .navbar .nav-top-menu {
        display: none;
    }
}

.fixed-header nav.template-main-menu ul li a {
    padding: 37px 15px;
}

.fixed-header .header-control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.fixed-header .header-control .inline-item {
    margin-right: 40px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .fixed-header .header-control .inline-item {
        margin-right: 25px;
    }
}

@media only screen and (max-width: 374px) {
    .fixed-header .header-control .inline-item {
        margin-right: 10px;
    }
}

.fixed-header .header-control .inline-item:after {
    content: "";
    height: 21px;
    width: 2px;
    background-color: #9996fc;
    position: absolute;
    top: 50%;
    right: -20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (max-width: 374px) {
    .fixed-header .header-control .inline-item:after {
        display: none;
    }
}

.fixed-header .header-control .inline-item:last-child {
    margin-right: 0;
}

.fixed-header .header-control .inline-item:last-child:after {
    display: none;
}

.fixed-header .header-control .input-group {
    background-color: #534fee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 250px;
}

@media only screen and (max-width: 1360px) {
    .fixed-header .header-control .input-group {
        width: 200px;
    }
}

@media only screen and (max-width: 1199px) {
    .fixed-header .header-control .input-group {
        width: 250px;
    }
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-control .input-group {
        width: 170px;
    }
}

.fixed-header .header-control .input-group .form-control {
    padding: 5px 20px;
    height: 42px;
    color: #ffffff;
    font-size: 14px;
    background-color: transparent;
}

.fixed-header .header-control .input-group .input-group-append {
    margin: 0 3px 0 0;
}

.fixed-header .header-control .input-group .submit-btn {
    background-color: #5edfff;
    border: none;
    color: #ffffff;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    position: relative;
}

.fixed-header .header-control .input-group .submit-btn:before {
    content: "";
    width: 13px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 6px;
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
    opacity: 0.8;
}

.fixed-header .header-control .input-group input.form-control::-webkit-input-placeholder,
      .fixed-header .header-control .input-group textarea.form-control::-webkit-input-placeholder {
    color: #c5c3ff;
    letter-spacing: 1px;
}

.fixed-header .header-control .dropdown {
    margin-right: 10px;
}

@media only screen and (max-width: 374px) {
    .fixed-header .header-control .dropdown {
        margin-right: 0;
    }
}

.fixed-header .header-control .dropdown:last-child {
    margin-right: 0;
}

.fixed-header .header-control .dropdown .dropdown-toggle {
    background-color: transparent;
    border: none;
    font-size: 22px;
    color: #4a47c3;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 479px) {
    .fixed-header .header-control .dropdown .dropdown-toggle {
        font-size: 20px;
    }
}

.fixed-header .header-control .dropdown .dropdown-toggle .notify-count {
    display: block;
    height: 18px;
    width: 18px;
    line-height: 18px;
    background-color: #f57f17;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    position: absolute;
    top: -4px;
    right: -6px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 479px) {
    .fixed-header .header-control .dropdown .dropdown-toggle .notify-count {
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 10px;
    }
}

.fixed-header .header-control .dropdown .dropdown-toggle:after {
    display: none;
}

.fixed-header .header-control .dropdown .dropdown-toggle:hover {
    color: #ffffff;
}

.fixed-header .header-control .dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    border: none;
    border-radius: 8px;
    min-width: 330px;
    padding: 0;
    top: 58px;
    right: -35px;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-control .dropdown .dropdown-menu {
        top: 43px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-header .header-control .dropdown .dropdown-menu {
        top: 38px;
        min-width: 280px;
    }
}

.fixed-header .header-control .dropdown .dropdown-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #ffffff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    top: -10px;
    right: 40px;
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown .dropdown-menu:before {
        right: auto;
        left: 50px;
    }
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading {
    padding: 15px 22px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading .heading-title {
    margin-bottom: 0;
    font-weight: 700;
    color: #000000;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading .heading-btn {
    text-align: right;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading .heading-btn a {
    font-size: 12px;
    color: #9e9faf;
    margin-right: 12px;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading .heading-btn a:hover {
    color: #615dfa;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-heading .heading-btn a:last-child {
    margin-right: 0;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body {
    background-color: #f6f9fd;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media {
    padding: 20px 25px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media:nth-child(even) {
    background-color: #ffffff;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media .item-img {
    margin-right: 15px;
    margin-top: 5px;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media .item-img img {
    border-radius: 50%;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media .media-body {
    position: relative;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media .item-title {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 1px;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media p {
    font-size: 14px;
    color: #71728c;
    line-height: 18px;
    font-weight: 600;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media .item-time {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #9e9faf;
    font-weight: 600;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-body .media:hover {
    background-color: #ffffff;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-footer .view-btn {
    text-align: center;
    background-color: #615dfa;
    display: block;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    border-radius: 0 0 8px 8px;
    padding: 15px 0;
}

.fixed-header .header-control .dropdown .dropdown-menu .item-footer .view-btn:hover {
    background-color: #5edfff;
}

.fixed-header .header-control .dropdown .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown-friend .dropdown-menu {
        left: -75px;
    }
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown-friend .dropdown-menu:before {
        left: 82px;
    }
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-heading {
    padding-bottom: 4px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body {
    background-color: #ffffff;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media {
    border-bottom: 1px solid #e8edf4;
    padding: 26px 22px 18px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media:last-child {
    border-bottom: none;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img {
    margin-top: 0;
    position: relative;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img:after {
    content: url("../../media/figure/chat_round_shape3.png");
    position: absolute;
    top: -5px;
    left: -5px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img img {
    height: 38px;
    width: 38px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img .chat-status {
    display: block;
    height: 12px;
    width: 12px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: -5px;
    left: -3px;
    z-index: 2;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img .chat-status.offline {
    background-color: #b0bacb;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-img .chat-status.online {
    background-color: #17dd17;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-title {
    line-height: 18px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-title a {
    color: #000000;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .item-title a:hover {
    color: #615dfa;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media p {
    font-size: 12px;
    color: #9e9faf;
    line-height: 18px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .btn-area {
    position: absolute;
    top: 0;
    right: 0;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .btn-area a {
    text-align: center;
    font-size: 12px;
    color: #ffffff;
    width: 29px;
    height: 28px;
    background-color: #cbdaef;
    border-radius: 4px;
}

.fixed-header .header-control .dropdown-friend .dropdown-menu .item-body .media .btn-area a:hover {
    background-color: #5edfff;
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown-cart .dropdown-menu {
        left: -40px;
    }
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-heading {
    padding-bottom: 4px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-heading .heading-title span {
    color: #5edfff;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body {
    background-color: #ffffff;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media {
    border-bottom: 1px solid #e8edf4;
    padding: 15px 22px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media:last-child {
    border-bottom: none;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-img img {
    border-radius: 4px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-title {
    line-height: 18px;
    margin-bottom: 2px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-title a {
    color: #000000;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-title a:hover {
    color: #615dfa;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-category {
    font-size: 12px;
    color: #9e9faf;
    line-height: 18px;
    margin-bottom: 4px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .item-price {
    color: #615dfa;
    font-weight: 700;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .btn-area {
    position: absolute;
    top: 0;
    right: 0;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .btn-area a {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #d1d5d9;
    width: 29px;
    height: 28px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
}

.fixed-header .header-control .dropdown-cart .dropdown-menu .item-body .media .btn-area a:hover {
    background-color: #5edfff;
    border-color: #5edfff;
    color: #ffffff;
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle {
    padding-right: 28px;
}

@media only screen and (max-width: 1360px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle {
        padding-right: 0;
    }
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 767px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle:before {
    content: "...";
    font-size: 22px;
    color: #eff4fb;
    font-weight: 700;
    position: absolute;
    top: 4px;
    right: 0;
}

@media only screen and (max-width: 1360px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle:before {
        display: none;
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1360px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle .media .media-body {
        display: none;
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .item-img {
    margin-right: 18px;
    position: relative;
}

@media only screen and (max-width: 479px) {
    .fixed-header .header-control .dropdown-admin .dropdown-toggle .item-img {
        margin-right: 5px;
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .item-img:after {
    content: url("../../media/figure/chat_round_shape4.png");
    position: absolute;
    top: -5px;
    left: -5px;
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .item-img img {
    border-radius: 50%;
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .item-img .acc-verified {
    position: absolute;
    top: -6px;
    right: -12px;
    height: 21px;
    width: 21px;
    line-height: 21px;
    background-color: #17dd17;
    border-radius: 50%;
    font-size: 18px;
    color: #ffffff;
    z-index: 1;
}

.fixed-header .header-control .dropdown-admin .dropdown-toggle .item-title {
    margin-bottom: 0;
    color: #eff4fb;
    font-weight: 400;
    font-size: 16px;
    display: block;
}

.fixed-header .header-control .dropdown-admin .dropdown-menu {
    top: 64px;
    min-width: 230px;
    border: 1px solid #dee5ef;
}

@media only screen and (max-width: 991px) {
    .fixed-header .header-control .dropdown-admin .dropdown-menu {
        top: 51px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-header .header-control .dropdown-admin .dropdown-menu {
        top: 45px;
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-menu:before {
    left: 70px;
}

@media only screen and (max-width: 1199px) {
    .fixed-header .header-control .dropdown-admin .dropdown-menu:before {
        left: auto;
        right: 65px;
    }
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options {
    padding: 15px 24px;
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options li {
    border-bottom: 1px solid #e5ebf3;
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options li:last-child {
    border-bottom: none;
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options a {
    color: #8c8c8c;
    font-size: 14px;
    padding-left: 18px;
    padding: 10px 0 10px 18px;
    display: block;
    position: relative;
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options a:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: #71728c;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.fixed-header .header-control .dropdown-admin .dropdown-menu .admin-options a:hover {
    color: #5edfff;
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown-message .dropdown-menu {
        left: -105px;
    }

    .fixed-header .header-control .dropdown-message .dropdown-menu:before {
        left: 112px;
    }
}

@media only screen and (max-width: 575px) {
    .fixed-header .header-control .dropdown-notification .dropdown-menu {
        left: -135px;
    }

    .fixed-header .header-control .dropdown-notification .dropdown-menu:before {
        left: 145px;
    }
}

.fixed-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
}

.fixed-sidebar .fixed-sidebar-left {
    position: absolute;
    height: 100%;
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fixed-sidebar .fixed-sidebar-left.small-sidebar {
    width: 90px;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar .fixed-sidebar-left.small-sidebar {
        height: 0;
    }
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .fixed-sidebar-left.small-sidebar {
        width: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-sidebar .fixed-sidebar-left.small-sidebar {
        width: 50px;
    }
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar .fixed-sidebar-left.small-sidebar .sidebar-menu-wrap {
        display: none;
    }
}

.fixed-sidebar .fixed-sidebar-left.large-sidebar {
    width: 270px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .fixed-sidebar-left.large-sidebar {
        width: 230px;
    }
}

.fixed-sidebar .fixed-sidebar-left.large-sidebar .sidebar-toggle {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 25px;
}

.fixed-sidebar .sidebar-toggle {
    background-color: #5854ef;
    text-align: center;
    height: 89px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .sidebar-toggle {
        height: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-sidebar .sidebar-toggle {
        height: 50px;
    }
}

.fixed-sidebar .sidebar-toggle .toggle-btn {
    width: 33px;
    border: none;
    background-color: transparent;
    padding: 0;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .sidebar-toggle .toggle-btn {
        width: 25px;
    }
}

.fixed-sidebar .sidebar-toggle .toggle-btn span {
    position: relative;
    display: block;
    height: 3px;
    width: 22px;
    background-color: #ffffff;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .sidebar-toggle .toggle-btn span {
        height: 3px;
        width: 15px;
        margin-bottom: 6px;
    }
}

.fixed-sidebar .sidebar-toggle .toggle-btn span:last-child {
    margin-bottom: 0;
}

.fixed-sidebar .sidebar-toggle .toggle-btn span:before {
    content: "";
    width: 8px;
    height: 3px;
    background-color: #5edfff;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: -11px;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .sidebar-toggle .toggle-btn span:before {
        height: 3px;
        width: 6px;
        left: -10px;
    }
}

.fixed-sidebar .sidebar-toggle .toggle-btn span:nth-child(even) {
    margin-left: 0;
}

.fixed-sidebar .sidebar-toggle .toggle-btn span:nth-child(even):before {
    left: auto;
    right: -11px;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar .sidebar-toggle .toggle-btn span:nth-child(even):before {
        right: -10px;
    }
}

.fixed-sidebar .sidebar-toggle .toggle-btn:hover span {
    background-color: #5edfff;
}

.fixed-sidebar .sidebar-menu-wrap {
    height: 100%;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu {
    padding: 30px 0;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu li {
    margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
    .fixed-sidebar .sidebar-menu-wrap .side-menu li {
        margin-bottom: 5px;
    }
}

.fixed-sidebar .sidebar-menu-wrap .side-menu li:last-child {
    margin-bottom: 0;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu .menu-link {
    font-size: 24px;
    color: #8793a3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0 10px 32px;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu .menu-link i {
    margin-right: 12px;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu .menu-link .menu-title {
    font-size: 14px;
    font-weight: 700;
}

.fixed-sidebar .sidebar-menu-wrap .side-menu .menu-link:hover {
    color: #5edfff;
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile {
    padding-bottom: 20px;
}

@media only screen and (min-width: 1200px) {
    .fixed-sidebar .sidebar-menu-wrap .top-menu-mobile {
        display: none;
    }
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-label {
    padding-left: 32px;
    color: #a8b1bc;
    margin: 20px 0 10px;
    font-weight: 700;
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-link {
    padding: 2px 0 2px 32px;
    color: #444444;
    font-size: 14px;
    font-weight: 600;
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-link:hover {
    color: #5edfff;
}

.fixed-sidebar .mCustomScrollbar {
    max-height: calc(100vh - 100px);
}

.fixed-sidebar.lg-menu-open {
    z-index: 15;
}

.fixed-sidebar.lg-menu-open .fixed-sidebar-left {
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fixed-sidebar.lg-menu-open .fixed-sidebar-left.small-sidebar {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.fixed-sidebar.lg-menu-open .fixed-sidebar-left.large-sidebar {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.fixed-sidebar.right {
    left: auto;
    right: 0;
}

.fixed-sidebar.right .fixed-sidebar-right {
    position: absolute;
    height: 100%;
    right: 0;
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar.right .fixed-sidebar-right {
        height: 0;
    }
}

.fixed-sidebar.right .fixed-sidebar-right.small-sidebar {
    width: 90px;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar.right .fixed-sidebar-right.small-sidebar {
        width: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-sidebar.right .fixed-sidebar-right.small-sidebar {
        width: 50px;
    }
}

.fixed-sidebar.right .sidebar-toggle {
    background-color: #5edfff;
    cursor: pointer;
}

.fixed-sidebar.right .sidebar-toggle:hover {
    background-color: #5854ef;
}

.fixed-sidebar.right .sidebar-toggle .chat-icon {
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: #ffffff;
    padding: 10px;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar.right .sidebar-toggle .chat-icon {
        font-size: 25px;
    }
}

.fixed-sidebar.right .user-chat-list {
    padding: 25px 0;
}

.fixed-sidebar.right .user-chat-list .chat-item {
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar.right .user-chat-list .chat-item {
        margin-bottom: 15px;
    }
}

.fixed-sidebar.right .user-chat-list .chat-item:last-child {
    margin-bottom: 0;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img {
    position: relative;
    display: inline-block;
}

@media only screen and (max-width: 991px) {
    .fixed-sidebar.right .user-chat-list .chat-item .author-img {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 767px) {
    .fixed-sidebar.right .user-chat-list .chat-item .author-img {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
    }
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img:before {
    content: url("../../media/figure/chat_round_shape2.png");
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img:after {
    content: url("../../media/figure/chat_round_shape.png");
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img img {
    border-radius: 50%;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img .chat-status {
    display: block;
    height: 12px;
    width: 12px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    top: -4px;
    left: -2px;
    z-index: 2;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img .chat-status.offline {
    background-color: #b0bacb;
}

.fixed-sidebar.right .user-chat-list .chat-item .author-img .chat-status.online {
    background-color: #17dd17;
}

.fixed-sidebar.right .user-chat-list .chat-item:hover .author-img:before {
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.fixed-sidebar.right .user-chat-list .chat-item:hover .author-img:after {
    visibility: hidden;
    opacity: 0;
}

.fixed-sidebar.right .sidebar-menu-wrap {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar.right .sidebar-menu-wrap {
        -webkit-transform: translateX(120px);
        -ms-transform: translateX(120px);
        transform: translateX(120px);
    }
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar.chat-head-hide .fixed-sidebar-right {
        height: 100%;
    }
}

.fixed-sidebar.chat-head-hide .sidebar-menu-wrap {
    -webkit-transform: translateX(120px);
    -ms-transform: translateX(120px);
    transform: translateX(120px);
}

@media only screen and (max-width: 1360px) {
    .fixed-sidebar.chat-head-hide .sidebar-menu-wrap {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.mCSB_scrollTools .mCSB_draggerContainer {
    display: none;
}

.mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.wrapper.open .closeMask {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.chat-conversion-box {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 100;
}

.chat-conversion-box .modal-dialog {
    max-width: 350px;
    width: 100%;
    position: fixed;
    right: 0;
    bottom: -10%;
    margin: 10px 90px 0 0;
    z-index: 10;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
    .chat-conversion-box .modal-dialog {
        margin: 10px 60px 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .chat-conversion-box .modal-dialog {
        margin: 10px 50px 0 0;
    }
}

@media only screen and (max-width: 479px) {
    .chat-conversion-box .modal-dialog {
        max-width: 250px;
    }
}

.chat-conversion-box .modal-content {
    border: none;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.chat-conversion-box .modal-header {
    padding: 0 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #615dfa;
    border: none;
}

.chat-conversion-box .modal-header .modal-title {
    color: #ffffff;
    position: relative;
}

.chat-conversion-box .modal-header .modal-title .online {
    display: block;
    height: 8px;
    width: 8px;
    background-color: #17dd17;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.chat-conversion-box .modal-header .chat-shrink {
    background-color: transparent;
    border: none;
    padding: 10px;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.chat-conversion-box .modal-header .chat-shrink:hover {
    color: #5edfff;
}

.chat-conversion-box .modal-header .chat-close {
    text-shadow: none;
    padding: 10px;
    margin: 0;
    color: #ffffff;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.chat-conversion-box .modal-header .chat-close:hover {
    color: #5edfff;
}

.chat-conversion-box .modal-body {
    padding: 30px 15px;
}

.chat-conversion-box .modal-body .chat-conversion li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.chat-conversion-box .modal-body .chat-conversion li:last-child {
    margin-bottom: 0;
}

.chat-conversion-box .modal-body .chat-conversion li .author-img {
    margin-right: 20px;
}

.chat-conversion-box .modal-body .chat-conversion li img {
    border-radius: 50%;
}

.chat-conversion-box .modal-body .chat-conversion li .author-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.chat-conversion-box .modal-body .chat-conversion li .author-text span {
    display: block;
    font-size: 13px;
    line-height: 20px;
    background-color: #5edfff;
    color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    position: relative;
}

.chat-conversion-box .modal-body .chat-conversion li .author-text span:before {
    content: "";
    width: 0;
    height: 0;
    border-right: 15px solid #5edfff;
    border-top: 2px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
    top: 50%;
    left: -15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.chat-conversion-box .modal-body .chat-conversion .chat-you {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.chat-conversion-box .modal-body .chat-conversion .chat-you .author-img {
    margin-left: 20px;
    margin-right: 0;
}

.chat-conversion-box .modal-body .chat-conversion .chat-you .author-text span:before {
    left: auto;
    right: -15px;
    border-right: none;
    border-left: 15px solid #5edfff;
    border-top: 2px solid transparent;
    border-bottom: 15px solid transparent;
}

.chat-conversion-box .modal-footer {
    border-color: #f3f3f3;
    display: block;
    padding: 20px 15px;
}

.chat-conversion-box .modal-footer .form-group {
    position: relative;
}

.chat-conversion-box .modal-footer .form-group .chat-plus-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 18px;
    color: #5edfff;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.chat-conversion-box .modal-footer .form-group .chat-plus-icon:hover {
    color: #615dfa;
}

.chat-conversion-box .modal-footer .form-group .file-attach-icon {
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.chat-conversion-box .modal-footer .form-group .file-attach-icon a {
    color: #615dfa;
    padding: 3px;
}

.chat-conversion-box .modal-footer .form-group .file-attach-icon a:hover {
    color: #5edfff;
}

.chat-conversion-box .modal-footer .form-group .file-attach-icon.show {
    visibility: visible;
    opacity: 1;
    right: 40px;
}

.chat-conversion-box .modal-footer .form-control {
    border-radius: 30px;
    font-size: 14px;
    height: 42px;
    border-color: #e0e0e0;
    padding: 10px 35px 10px 20px;
}

.chat-conversion-box .modal-footer input.form-control::-webkit-input-placeholder,
    .chat-conversion-box .modal-footer textarea.form-control::-webkit-input-placeholder {
    color: #cccccc;
}

.chat-conversion-box.modal-show {
    visibility: visible;
    opacity: 1;
}

.chat-conversion-box.modal-show .modal-dialog {
    bottom: 0;
}

.chat-conversion-box.shrink .modal-body {
    display: none;
}

.chat-conversion-box.shrink .modal-footer {
    display: none;
}

.mobile-nav-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-right: 20px;
}

@media only screen and (max-width: 767px) {
    .mobile-nav-item {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .mobile-nav-item {
        margin-right: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .mobile-nav-item {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .mobile-nav-item .mobile-logo a {
        padding: 0 0 0 35px;
    }
}

.mobile-toggler {
    display: none;
}

.mobile-toggler button {
    border: none;
    background-color: transparent;
    font-size: 24px;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0;
}

.mobile-toggler button:focus {
    outline: none;
}

.mobile-toggler button:hover {
    opacity: 0.9;
}

.mobile-toggle-close {
    display: none;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 4px;
    padding: 5px 6px;
    color: #ccc;
    font-size: 18px;
    position: absolute;
    top: 20px;
    right: 20px;
    line-height: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.mobile-toggle-close:focus {
    outline: none;
}

.mobile-toggle-close:hover {
    color: #5edfff;
    background-color: #ffffff;
}

.mobile-menu-wrapper .header-menu {
    padding: 10px 0;
}

.mobile-menu-wrapper .mobile-toggler {
    display: block;
}

.mobile-menu-wrapper nav.template-main-menu {
    width: 270px;
    position: fixed;
    top: 0;
    left: -280px;
    bottom: 0;
    background-color: #615dfa;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    padding: 80px 10px 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 5;
}

.mobile-menu-wrapper nav.template-main-menu .mobile-toggle-close {
    display: block;
}

.mobile-menu-wrapper nav.template-main-menu > ul {
    overflow-y: auto;
    height: 100%;
    display: block !important;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li > a {
    text-align: center;
    padding: 20px !important;
    color: #ffffff;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li > a:hover {
    color: #ffffff;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li a.have-sub:after {
    display: none;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li .sub-menu {
    display: none;
    border-top: none;
    width: auto;
    position: relative;
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: inherit;
    -ms-transform-origin: inherit;
    transform-origin: inherit;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li .sub-menu:before {
    display: none;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li .sub-menu li a {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0 0 10px 0;
    text-align: center;
    color: #e6e5ff;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li .sub-menu li a:before {
    display: none;
}

.mobile-menu-wrapper nav.template-main-menu > ul > li .sub-menu li a:hover {
    color: #ffffff;
    padding: 0 0 10px 0;
}

.mobile-menu-expand nav.template-main-menu {
    left: 0;
}

/*------------------- 4.7 Pagination -------------------*/
.pagination {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 30px;
}

.pagination li {
    display: inline-block;
}

.pagination li a {
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    padding: 10px 17px;
    border-radius: 5px;
}

.pagination li a:hover {
    background-color: #615dfa;
    color: #ffffff;
}

.pagination li a.active {
    background-color: #615dfa;
    color: #ffffff;
}

/*------------------- 4.8 Preloader -------------------*/
#preloader {
    background: #ffffff url("../../media/preloader.gif") no-repeat scroll center center;
    height: 100%;
    left: 0;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999;
}

/*------------------- 4.9 Section -------------------*/
.section {
    padding: 114px 0 100px;
}

@media only screen and (max-width: 1199px) {
    .section {
        padding: 92px 0 80px;
    }
}

@media only screen and (max-width: 991px) {
    .section {
        padding: 72px 0 60px;
    }
}

.section .section-heading {
    text-align: center;
    margin-bottom: 40px;
}

.section .section-heading p {
    color: #71728c;
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 991px) {
    .section .section-heading p {
        width: 100%;
    }
}

.section .flex-heading {
    text-align: left;
}

@media only screen and (max-width: 991px) {
    .section .flex-heading {
        text-align: center;
    }
}

.section .flex-heading p {
    width: 80%;
    margin: 0;
}

@media only screen and (max-width: 991px) {
    .section .flex-heading p {
        width: 100%;
    }
}

.page-content {
    padding: 160px 90px 0;
}

@media only screen and (max-width: 1360px) {
    .page-content {
        padding: 160px 0 0;
    }
}

@media only screen and (max-width: 991px) {
    .page-content {
        padding: 100px 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .page-content {
        padding: 90px 0 0;
    }
}

.section-typography {
    padding: 120px 0;
}

.section-typography h1, .section-typography h2, .section-typography h3, .section-typography h4, .section-typography h5, .section-typography h6 {
    font-weight: 400;
    margin-bottom: 0;
}

.section-typography .table td {
    padding: 20px 10px;
    vertical-align: middle;
}

/*=======================================================================
5. Template Page Style
=========================================================================*/
/*------------------- 5.1 About -------------------*/
.about-us {
    padding: 120px 0 110px;
}

@media only screen and (max-width: 1199px) {
    .about-us {
        padding: 100px 0 90px;
    }
}

@media only screen and (max-width: 991px) {
    .about-us {
        padding: 75px 0 80px;
    }
}

@media only screen and (max-width: 991px) {
    .about-us .about-us-content {
        margin-bottom: 50px;
    }
}

.about-us .about-us-content > .item-subtitle {
    color: #615dfa;
    margin-bottom: 12px;
    font-weight: 600;
}

.about-us .about-us-content > .item-title {
    width: 80%;
    margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
    .about-us .about-us-content > .item-title {
        width: 100%;
    }
}

.about-us .about-us-content p {
    width: 80%;
}

@media only screen and (max-width: 991px) {
    .about-us .about-us-content p {
        width: 100%;
    }
}

.about-us .about-us-content .progress-box {
    margin-top: 55px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-us .about-us-content .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 45px;
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-content .media {
        margin-right: 10px;
    }
}

.about-us .about-us-content .media:last-child {
    margin-right: 0;
}

.about-us .about-us-content .media .item-icon {
    margin-right: 20px;
    font-size: 36px;
    color: #5edfff;
}

@media only screen and (max-width: 767px) {
    .about-us .about-us-content .media .item-icon {
        font-size: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-content .media .item-icon {
        font-size: 26px;
    }
}

.about-us .about-us-content .media .item-title {
    font-size: 36px;
    font-weight: 800;
    color: #000000;
    margin-bottom: 6px;
}

@media only screen and (max-width: 767px) {
    .about-us .about-us-content .media .item-title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-content .media .item-title {
        font-size: 24px;
    }
}

.about-us .about-us-content .media .item-subtitle {
    color: #71728c;
}

.about-us .about-us-img {
    text-align: right;
    position: relative;
}

.about-us .about-us-img .item-img {
    display: inline-block;
    position: relative;
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-img .item-img {
        display: block;
    }
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-img .item-img img {
        width: 100%;
    }
}

.about-us .about-us-img .item-img:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.about-us .about-us-img .item-img:after {
    content: url("../../media/about/shape_1.png");
    position: absolute;
    right: -10px;
    bottom: -55px;
}

@media only screen and (max-width: 767px) {
    .about-us .about-us-img .item-img:after {
        display: none;
    }
}

.about-us .about-us-img .item-video {
    position: absolute;
    bottom: -20px;
    left: -10px;
    box-shadow: 0 6px 43px 0 rgba(16, 9, 30, 0.3);
}

@media only screen and (max-width: 991px) {
    .about-us .about-us-img .item-video {
        bottom: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .about-us .about-us-img .item-video {
        display: none;
    }
}

.about-us .about-us-img .item-video:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.about-us .about-us-img .item-video .video-icon {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.about-us .about-us-img .item-video .video-icon .play-btn {
    font-size: 22px;
    height: 60px;
    width: 60px;
    line-height: 54px;
    background-color: #615dfa;
    border: 3px solid #ffffff;
    color: #ffffff;
    border-radius: 50%;
}

.about-us .about-us-img .item-video .video-icon .play-btn i {
    margin-left: 5px;
}

.about-us .about-us-img .item-video .video-icon .play-btn:hover {
    background-color: #5edfff;
}

/*------------------- 5.2 Banner -------------------*/
.hero-banner {
    margin-top: -97px;
    padding: 200px 0 120px;
    min-height: calc(100vh - 180px);
    background-color: #615dfa;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    z-index: 5;
}

@media only screen and (max-width: 1199px) {
    .hero-banner {
        padding: 180px 0 100px;
    }
}

@media only screen and (max-width: 991px) {
    .hero-banner {
        padding: 160px 0 80px;
        min-height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .hero-banner {
        padding: 140px 0 60px;
    }
}

.hero-banner:before {
    content: url("../../media/banner/hero_1.jpg");
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.hero-banner .hero-content {
    position: relative;
    z-index: 3;
}

.hero-banner .hero-content .item-title {
    color: #ffffff;
    margin-bottom: 14px;
}

.hero-banner .hero-content p {
    color: #ffffff;
    width: 35%;
    margin-bottom: 40px;
}

@media only screen and (max-width: 991px) {
    .hero-banner .hero-content p {
        width: 70%;
    }
}

@media only screen and (max-width: 575px) {
    .hero-banner .hero-content p {
        width: 100%;
    }
}

.hero-banner .hero-content .item-number {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 6px;
}

@media only screen and (max-width: 1199px) {
    .hero-banner .hero-content .item-number {
        font-size: 38px;
    }
}

@media only screen and (max-width: 991px) {
    .hero-banner .hero-content .item-number {
        font-size: 34px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-banner .hero-content .item-number {
        font-size: 30px;
    }
}

.hero-banner .hero-content .conn-people {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 38px;
}

.hero-banner .hero-content .button-slide {
    border-color: #ffffff;
    color: #ffffff;
    background-color: #615dfa;
}

.hero-banner .map-line {
    position: absolute;
    bottom: 0;
    left: 11%;
    z-index: -1;
}

@media only screen and (max-width: 1440px) {
    .hero-banner .map-line {
        left: 0;
    }
}

@media only screen and (max-width: 1199px) {
    .hero-banner .map-line {
        left: auto;
        right: 0;
    }
}

.hero-banner .map-line .map-marker li {
    position: absolute;
}

.hero-banner .map-line .map-marker li:nth-child(1n) {
    top: 0;
    right: 40%;
}

.hero-banner .map-line .map-marker li:nth-child(2n) {
    top: 33%;
    right: 50%;
}

.hero-banner .map-line .map-marker li:nth-child(3n) {
    top: 60%;
    right: 37%;
}

.hero-banner .map-line .map-marker li:nth-child(4n) {
    top: 38%;
    right: 21%;
}

.hero-banner .map-line .map-marker li:after {
    content: "";
    height: 6px;
    width: 33px;
    background-color: #403cd9;
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: -5px;
    -webkit-animation: pulse 1500ms infinite;
    animation: pulse 1500ms infinite;
}

@media only screen and (max-width: 1199px) {
    .hero-banner .leftside-image {
        display: none;
    }
}

.hero-banner .leftside-image .cartoon-image {
    position: absolute;
    bottom: 100px;
    right: 80px;
    z-index: 2;
}

@media only screen and (max-width: 1440px) {
    .hero-banner .leftside-image .cartoon-image {
        right: 0;
    }

    .hero-banner .leftside-image .cartoon-image img {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 1366px) {
    .hero-banner .leftside-image .cartoon-image {
        right: -10%;
        bottom: 20px;
    }

    .hero-banner .leftside-image .cartoon-image img {
        -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
        transform: scale(0.5);
    }
}

.hero-banner .leftside-image .shape-image {
    position: absolute;
    top: 90px;
    right: 60px;
    z-index: 1;
}

@media only screen and (max-width: 1440px) {
    .hero-banner .leftside-image .shape-image {
        right: 0;
    }

    .hero-banner .leftside-image .shape-image img {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 1366px) {
    .hero-banner .leftside-image .shape-image {
        right: -14%;
        top: 80px;
    }

    .hero-banner .leftside-image .shape-image img {
        -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
        transform: scale(0.5);
    }
}

.banner-newsletter {
    background-color: #f0f4f6;
    padding: 120px 0 190px;
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .banner-newsletter {
        padding: 95px 0 190px;
    }
}

@media only screen and (max-width: 991px) {
    .banner-newsletter {
        padding: 75px 0 180px;
    }
}

@media only screen and (max-width: 479px) {
    .banner-newsletter {
        padding: 75px 0 80px;
    }
}

.banner-newsletter .newsletter-box {
    text-align: center;
}

.banner-newsletter .newsletter-box .item-title {
    margin-bottom: 12px;
}

.banner-newsletter .newsletter-box p {
    color: #71728c;
    width: 60%;
    margin: 0 auto 22px;
}

@media only screen and (max-width: 767px) {
    .banner-newsletter .newsletter-box p {
        width: 100%;
    }
}

@media only screen and (max-width: 479px) {
    .banner-newsletter .newsletter-box .input-group {
        display: block;
        background-color: transparent;
    }
}

.banner-newsletter .newsletter-box .input-group .form-control {
    height: 52px;
    border-radius: 30px;
    font-size: 14px;
}

@media only screen and (max-width: 479px) {
    .banner-newsletter .newsletter-box .input-group .form-control {
        width: 100%;
        margin-bottom: 15px;
    }
}

.banner-newsletter .newsletter-box .input-group .input-group-append .button-slide {
    background-color: #5edfff;
    border: none;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.banner-newsletter .newsletter-box .input-group .input-group-append .button-slide .btn-icon {
    margin-left: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.banner-newsletter .newsletter-box .input-group .input-group-append .button-slide:after {
    background-color: transparent;
}

.banner-newsletter .newsletter-box .input-group .input-group-append .button-slide:hover {
    background-color: #5bc6e0;
}

@media only screen and (max-width: 479px) {
    .banner-newsletter .newsletter-box .input-group .input-group-append {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.banner-newsletter .section-shape {
    position: relative;
}

.banner-newsletter .section-shape li {
    position: absolute;
}

.banner-newsletter .section-shape li:nth-child(1n) {
    top: 50px;
    left: 0;
    -webkit-animation: movingleftright1 15000ms infinite;
    animation: movingleftright1 15000ms infinite;
}

.banner-newsletter .section-shape li:nth-child(2n) {
    top: 200px;
    left: 22%;
    -webkit-animation: movingleftright2 20000ms infinite;
    animation: movingleftright2 20000ms infinite;
}

.banner-newsletter .section-shape li:nth-child(3n) {
    top: 220px;
    left: 50%;
    -webkit-animation: movingleftright3 25000ms infinite;
    animation: movingleftright3 25000ms infinite;
}

.banner-newsletter .section-shape li:nth-child(4n) {
    top: 130px;
    left: 90%;
    -webkit-animation: movingleftright2 15000ms infinite;
    animation: movingleftright2 15000ms infinite;
}

.banner-newsletter .section-shape li:nth-child(5n) {
    top: -130px;
    left: 40%;
    -webkit-animation: movingleftright1 30000ms infinite;
    animation: movingleftright1 30000ms infinite;
}

.banner-newsletter .section-cloud li {
    position: absolute;
}

.banner-newsletter .section-cloud li:nth-child(1n) {
    top: 20%;
    -webkit-animation: moveclouds 35s linear infinite;
    animation: moveclouds 35s linear infinite;
}

.banner-newsletter .section-cloud li:nth-child(2n) {
    bottom: 0;
    top: auto;
    -webkit-animation: moveclouds 45s linear infinite;
    animation: moveclouds 45s linear infinite;
}

.banner-newsletter .section-cloud li:nth-child(3n) {
    -webkit-animation: moveclouds 36s linear infinite;
    animation: moveclouds 36s linear infinite;
}

.banner-newsletter .section-cloud li:nth-child(4n) {
    -webkit-animation: moveclouds 40s linear infinite;
    animation: moveclouds 40s linear infinite;
}

.banner-apps {
    background-color: #f8f8f8;
    position: relative;
}

@media only screen and (max-width: 991px) {
    .banner-apps {
        padding-top: 70px;
        text-align: center;
    }
}

.banner-apps:before {
    content: url("../../media/banner/shape_2.png");
    position: absolute;
    bottom: 0;
    left: -20px;
    line-height: 0;
}

@media only screen and (max-width: 991px) {
    .banner-apps:before {
        display: none;
    }
}

.banner-apps .banner-content .item-title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 1199px) {
    .banner-apps .banner-content .item-title {
        font-size: 36px;
    }
}

@media only screen and (max-width: 991px) {
    .banner-apps .banner-content .item-title {
        font-size: 32px;
    }
}

@media only screen and (max-width: 767px) {
    .banner-apps .banner-content .item-title {
        font-size: 28px;
    }
}

@media only screen and (max-width: 575px) {
    .banner-apps .banner-content .item-title {
        font-size: 26px;
    }
}

.banner-apps .banner-content p {
    width: 90%;
    color: #71728c;
    margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
    .banner-apps .banner-content p {
        width: 100%;
    }
}

.banner-apps .banner-content .button-slide {
    background-color: #615dfa;
    color: #ffffff;
    border-color: #615dfa;
}

.banner-apps .banner-img {
    padding-top: 100px;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .banner-apps .banner-img {
        padding-top: 80px;
    }
}

.banner-apps .banner-img:before {
    content: url("../../media/banner/shape_3.png");
    position: absolute;
    top: 0;
    right: 30px;
    z-index: -1;
}

@media only screen and (max-width: 991px) {
    .banner-apps .banner-img:before {
        display: none;
    }
}

.banner-apps .banner-img:after {
    content: url("../../media/banner/shape_4.png");
    position: absolute;
    bottom: 70px;
    left: 10px;
    z-index: -1;
}

.banner-apps .banner-img .apps-view {
    text-align: center;
    position: relative;
}

.banner-apps .banner-img .apps-view:before {
    content: url("../../media/banner/shape_5.png");
    position: absolute;
    top: 30px;
    left: 30px;
    -webkit-animation: movingleftright2 15000ms linear infinite;
    animation: movingleftright2 15000ms linear infinite;
}

.banner-apps .banner-img .apps-view:after {
    content: url("../../media/banner/shape_6.png");
    position: absolute;
    bottom: 25%;
    right: 60px;
    -webkit-animation: movingleftright1 20000ms linear infinite;
    animation: movingleftright1 20000ms linear infinite;
}

/*------------------- 5.3 Blog -------------------*/
.blog-grid .blog-box {
    margin-bottom: 30px;
}

.blog-grid .blog-box .blog-img {
    position: relative;
    margin-bottom: 18px;
}

.blog-grid .blog-box .blog-img a {
    display: block;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.blog-grid .blog-box .blog-img a:after {
    content: "";
    height: 60%;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6), transparent);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.blog-grid .blog-box .blog-img img {
    border-radius: 8px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.blog-grid .blog-box .blog-img .blog-date {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #615dfa;
    border-radius: 0 5px 0 5px;
    font-weight: 700;
    color: #ffffff;
    padding: 5px 16px;
}

.blog-grid .blog-box .blog-img .blog-date i {
    margin-right: 8px;
}

.blog-grid .blog-box .blog-content .blog-title {
    margin-bottom: 14px;
    font-size: 22px;
}

@media only screen and (max-width: 991px) {
    .blog-grid .blog-box .blog-content .blog-title {
        font-size: 20px;
    }
}

.blog-grid .blog-box .blog-content .blog-title a {
    color: #000000;
}

.blog-grid .blog-box .blog-content .blog-title a:hover {
    color: #5edfff;
}

.blog-grid .blog-box .blog-content .entry-meta li {
    display: inline-block;
    color: #71728c;
    position: relative;
}

.blog-grid .blog-box .blog-content .entry-meta li:last-child:after {
    display: none;
}

.blog-grid .blog-box .blog-content .entry-meta li:after {
    content: "/";
    padding: 0 10px 0 12px;
}

.blog-grid .blog-box .blog-content .entry-meta li a {
    color: #71728c;
}

.blog-grid .blog-box .blog-content .entry-meta li a:hover {
    color: #5edfff;
}

.blog-grid .blog-box .blog-content .entry-meta li i {
    color: #bcbcc7;
    margin-right: 10px;
}

.blog-grid .blog-box:hover .blog-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

/*------------------- 5.4 Community -------------------*/
.community-network {
    background-color: #f6f6f6;
    padding: 125px 0;
    background-image: url("../../media/figure/map_1.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 1199px) {
    .community-network {
        padding: 100px 0;
    }
}

@media only screen and (max-width: 991px) {
    .community-network {
        padding: 75px 0;
        background-image: none;
    }
}

.community-network .bg-shape {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

@media only screen and (max-width: 767px) {
    .community-network .bg-shape {
        display: none;
    }
}

.community-network .community-content .item-title {
    width: 80%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
    .community-network .community-content .item-title {
        width: 100%;
    }
}

.community-network .community-content .item-title span {
    color: #615dfa;
}

.community-network .community-content p {
    color: #71728c;
}

@media only screen and (max-width: 991px) {
    .community-network .community-content p {
        margin-bottom: 0;
    }
}

.community-network .map-marker {
    position: relative;
}

@media only screen and (max-width: 991px) {
    .community-network .map-marker {
        display: none;
    }
}

.community-network .map-marker li {
    position: absolute;
}

.community-network .map-marker li:nth-child(1n) {
    top: 35px;
    left: 11%;
}

.community-network .map-marker li:nth-child(2n) {
    top: 60px;
    left: 25%;
}

.community-network .map-marker li:nth-child(3n) {
    top: 230px;
    left: 28%;
}

.community-network .map-marker li:nth-child(4n) {
    top: 115px;
    left: 37%;
}

.community-network .map-marker li:nth-child(5n) {
    top: 185px;
    left: 16%;
}

.community-network .map-marker li:after {
    content: "";
    height: 6px;
    width: 33px;
    background-color: #bcbcbc;
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: -5px;
    -webkit-animation: pulse 1500ms infinite;
    animation: pulse 1500ms infinite;
}

/*------------------- 5.5 Contact Us -------------------*/
.contact-page .map-area .google-map {
    height: 520px;
}

@media only screen and (max-width: 991px) {
    .contact-page .map-area .google-map {
        height: 450px;
    }
}

@media only screen and (max-width: 575px) {
    .contact-page .map-area .google-map {
        height: 400px;
    }
}

.contact-page .contact-box-wrap {
    background-color: #f0f4f6;
}

.contact-page .contact-box-wrap .contact-form {
    margin-top: -115px;
    background-color: #ffffff;
    padding: 60px 70px 50px;
    box-shadow: 0 18px 40px 0 rgba(16, 9, 30, 0.06);
    position: relative;
}

@media only screen and (max-width: 767px) {
    .contact-page .contact-box-wrap .contact-form {
        padding: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .contact-page .contact-box-wrap .contact-form {
        padding: 30px 15px;
    }
}

@media only screen and (max-width: 991px) {
    .contact-page .contact-box-wrap .contact-box {
        margin-bottom: 50px;
    }
}

.contact-page .contact-box-wrap .contact-box .item-title {
    font-size: 24px;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
    .contact-page .contact-box-wrap .contact-box .item-title {
        font-size: 20px;
    }
}

.contact-page .contact-box-wrap .contact-box .item-title:before {
    content: "";
    height: 3px;
    width: 6px;
    background-color: #615dfa;
    position: absolute;
    bottom: 0;
    left: 0;
}

.contact-page .contact-box-wrap .contact-box .item-title:after {
    content: "";
    height: 3px;
    width: 20px;
    background-color: #615dfa;
    position: absolute;
    bottom: 0;
    left: 8px;
}

.contact-page .contact-box-wrap .contact-method {
    padding-left: 20px;
    margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
    .contact-page .contact-box-wrap .contact-method {
        padding-left: 0;
    }
}

.contact-page .contact-box-wrap .contact-method li {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .contact-page .contact-box-wrap .contact-method li {
        font-size: 16px;
    }
}

.contact-page .contact-box-wrap .contact-method li i {
    color: #5edfff;
    margin-right: 14px;
}

/*------------------- 5.6 Groups -------------------*/
.groups-popular .groups-box {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
}

.groups-popular .groups-box .item-img {
    position: relative;
    border-radius: 4px;
    z-index: 1;
}

.groups-popular .groups-box .item-img:after {
    content: "";
    height: 100%;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8), transparent);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.groups-popular .groups-box .item-img img {
    border-radius: 4px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .groups-popular .groups-box .item-img img {
        width: 100%;
    }
}

.groups-popular .groups-box .item-content {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.groups-popular .groups-box .item-content .item-title {
    font-size: 18px;
}

.groups-popular .groups-box .item-content .item-title a {
    color: #ffffff;
}

.groups-popular .groups-box .item-content .item-title a:hover {
    color: #5edfff;
}

.groups-popular .groups-box .item-content .groups-member {
    font-size: 13px;
    font-weight: 700;
    background-color: #615dfa;
    color: #ffffff;
    display: inline-block;
    border-radius: 12px;
    padding: 0 14px;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.groups-popular .groups-box:hover .item-img img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.groups-popular .groups-box:hover .item-content {
    top: 50%;
}

.groups-popular .groups-box:hover .item-content .groups-member {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.groups-popular .see-all-btn {
    text-align: center;
    margin-top: 55px;
    margin-bottom: 20px;
}

/*------------------- 5.7 Location -------------------*/
.location-find .location-box {
    position: relative;
    margin-bottom: 20px;
}

.location-find .location-box .item-img {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.location-find .location-box .item-img:after {
    content: "";
    height: 60%;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.9), transparent);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    border-radius: 0 0 4px 4px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.location-find .location-box .item-img a {
    display: block;
}

.location-find .location-box .item-img img {
    border-radius: 4px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.location-find .location-box .item-content {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.location-find .location-box .item-content .item-title {
    font-size: 22px;
    position: relative;
    padding-left: 16px;
    margin-bottom: 0;
}

.location-find .location-box .item-content .item-title:before {
    content: "";
    height: 18px;
    width: 4px;
    background-color: #6f6f6f;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.location-find .location-box .item-content .item-title a {
    color: #ffffff;
}

.location-find .location-box .item-content .item-title a:hover {
    color: #5edfff;
}

.location-find .location-box:hover .item-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.location-find .location-box:hover .item-content .item-title:before {
    background-color: #5edfff;
}

/*------------------- 5.8 Team -------------------*/
.team-circle .nav-tabs {
    border-bottom: none;
    position: relative;
    height: 100%;
}

@media only screen and (max-width: 991px) {
    .team-circle .nav-tabs {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.team-circle .nav-tabs .nav-item {
    margin-bottom: 0;
    -webkit-transform: scale(0.55);
    -ms-transform: scale(0.55);
    transform: scale(0.55);
    position: absolute;
}

@media only screen and (max-width: 991px) {
    .team-circle .nav-tabs .nav-item {
        -webkit-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        transform: scale(1) !important;
        position: initial;
        margin-right: 15px;
    }

    .team-circle .nav-tabs .nav-item:last-child {
        margin-right: 0;
    }
}

@media only screen and (max-width: 575px) {
    .team-circle .nav-tabs .nav-item {
        margin-bottom: 15px;
    }
}

.team-circle .nav-tabs .nav-item:nth-child(1n) {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
    top: -8%;
    right: -17%;
}

.team-circle .nav-tabs .nav-item:nth-child(2n) {
    -webkit-transform: scale(0.55);
    -ms-transform: scale(0.55);
    transform: scale(0.55);
    top: 34%;
    left: -25%;
}

.team-circle .nav-tabs .nav-item:nth-child(3n) {
    -webkit-transform: scale(0.55);
    -ms-transform: scale(0.55);
    transform: scale(0.55);
    top: 44%;
    right: -35%;
}

.team-circle .nav-tabs .nav-link {
    padding: 0;
    border: none;
    background-color: transparent;
}

.team-circle .nav-tabs .nav-link img {
    border-radius: 50%;
}

@media only screen and (max-width: 991px) {
    .team-circle .nav-tabs .nav-link img {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .team-circle .nav-tabs .nav-link img {
        height: 70px;
        width: 70px;
    }
}

.team-circle .nav-tabs-right .nav-item:nth-child(1n) {
    -webkit-transform: scale(0.55);
    -ms-transform: scale(0.55);
    transform: scale(0.55);
    top: -14%;
    left: -30%;
    right: auto;
}

.team-circle .nav-tabs-right .nav-item:nth-child(2n) {
    -webkit-transform: scale(0.55);
    -ms-transform: scale(0.55);
    transform: scale(0.55);
    top: 0;
    left: auto;
    right: -17%;
}

.team-circle .nav-tabs-right .nav-item:nth-child(3n) {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
    top: 34%;
    right: -40%;
}

.team-circle .tab-content {
    padding: 50px 30px 110px;
}

@media only screen and (max-width: 991px) {
    .team-circle .tab-content {
        padding: 50px 0;
    }
}

@media only screen and (max-width: 575px) {
    .team-circle .tab-content {
        padding: 30px 0 50px;
    }
}

.team-circle .tab-content .tab-pane {
    text-align: center;
}

.team-circle .team-box {
    display: inline-block;
    position: relative;
}

.team-circle .team-box .item-img {
    position: relative;
}

.team-circle .team-box .item-img:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(93, 93, 250, 0.85);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 1;
}

.team-circle .team-box .item-img img {
    border-radius: 50%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.team-circle .team-box .item-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}

.team-circle .team-box .item-content .item-title {
    font-size: 24px;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 8px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-circle .team-box .item-content .item-title a {
    color: #ffffff;
}

.team-circle .team-box .item-content .item-title a:hover {
    color: #5edfff;
}

.team-circle .team-box .item-content .item-title:before {
    content: "";
    height: 3px;
    width: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: -32px;
    right: 0;
    bottom: 0;
    margin: 0 auto;
}

.team-circle .team-box .item-content .item-title:after {
    content: "";
    height: 3px;
    width: 30px;
    background-color: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 13px;
    right: 0;
    bottom: 0;
    margin: 0 auto;
}

.team-circle .team-box .item-content .group-count {
    font-size: 18px;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team-circle .team-box .item-content .group-count span {
    font-weight: 700;
}

.team-circle .see-all-btn {
    text-align: center;
    padding: 10px 0 20px;
}

.team-circle .shape-wrap li {
    position: absolute;
    z-index: -1;
}

.team-circle .shape-wrap li:nth-child(1n) {
    top: -4%;
    left: 16%;
    -webkit-animation: movingleftright1 15000ms linear infinite;
    animation: movingleftright1 15000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(2n) {
    top: 33%;
    left: 9%;
    -webkit-animation: movingleftright2 25000ms linear infinite;
    animation: movingleftright2 25000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(3n) {
    top: 78%;
    left: 18%;
    -webkit-animation: movingleftright3 20000ms linear infinite;
    animation: movingleftright3 20000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(4n) {
    top: 8%;
    left: 35%;
    -webkit-animation: movingleftright2 20000ms linear infinite;
    animation: movingleftright2 20000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(5n) {
    top: 73%;
    left: 46%;
    -webkit-animation: movingleftright1 30000ms linear infinite;
    animation: movingleftright1 30000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(6n) {
    top: 10%;
    left: 61%;
    -webkit-animation: movingleftright3 25000ms linear infinite;
    animation: movingleftright3 25000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(7n) {
    top: 4%;
    left: 82%;
    -webkit-animation: movingleftright2 30000ms linear infinite;
    animation: movingleftright2 30000ms linear infinite;
}

.team-circle .shape-wrap li:nth-child(8n) {
    top: 70%;
    left: 60%;
    -webkit-animation: movingleftright1 40000ms linear infinite;
    animation: movingleftright1 40000ms linear infinite;
}

/*------------------- 5.9 Testimonial -------------------*/
.testimonial-carousel {
    padding: 120px 0 100px;
    background-image: url("../../media/testimonial/bg_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .testimonial-carousel {
        padding: 95px 0 80px;
    }
}

@media only screen and (max-width: 991px) {
    .testimonial-carousel {
        padding: 75px 0 60px;
    }
}

.testimonial-carousel:before {
    content: url("../../media/testimonial/shape_2.png");
    position: absolute;
    right: 18%;
    top: 0;
}

@media only screen and (max-width: 767px) {
    .testimonial-carousel:before {
        display: none;
    }
}

.testimonial-carousel .testimonial-content {
    text-align: center;
    margin-bottom: 34px;
}

.testimonial-carousel .testimonial-content .item-title {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 4px;
}

.testimonial-carousel .testimonial-content .item-subtitle {
    font-size: 14px;
    color: #d4d3ff;
    margin-bottom: 25px;
}

.testimonial-carousel .testimonial-content p {
    font-size: 18px;
    color: #ffffff;
}

.testimonial-carousel .slick-nav {
    max-width: 300px;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.testimonial-carousel .slick-nav .slick-list {
    padding: 0 !important;
}

.testimonial-carousel .slick-nav .nav-item {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 8px;
    margin-bottom: 20px;
    position: relative;
}

.testimonial-carousel .slick-nav .nav-item:before {
    content: url("../../media/testimonial/shape_1.png");
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.testimonial-carousel .slick-nav .nav-item img {
    display: inline-block;
    border-radius: 50%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
    box-shadow: 0 17px 21px 0 rgba(21, 17, 164, 0.46);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.testimonial-carousel .slick-nav .slick-center:before {
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.testimonial-carousel .slick-nav .slick-center img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.testimonial-carousel .shape-wrap li {
    position: absolute;
}

.testimonial-carousel .shape-wrap li:nth-child(1n) {
    top: 16%;
    left: 13%;
    -webkit-animation: movingleftright1 15000ms linear infinite;
    animation: movingleftright1 15000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(2n) {
    top: 2%;
    left: 45%;
    -webkit-animation: movingleftright2 25000ms linear infinite;
    animation: movingleftright2 25000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(3n) {
    top: 20%;
    left: 72%;
    -webkit-animation: movingleftright3 20000ms linear infinite;
    animation: movingleftright3 20000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(4n) {
    top: 18%;
    left: 90%;
    -webkit-animation: movingleftright2 30000ms linear infinite;
    animation: movingleftright2 30000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(5n) {
    bottom: 22%;
    top: auto;
    left: 10%;
    -webkit-animation: movingleftright1 20000ms linear infinite;
    animation: movingleftright1 20000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(6n) {
    bottom: 6%;
    top: auto;
    left: 48%;
    -webkit-animation: movingleftright3 15000ms linear infinite;
    animation: movingleftright3 15000ms linear infinite;
}

.testimonial-carousel .shape-wrap li:nth-child(7n) {
    bottom: 12%;
    top: auto;
    left: 80%;
    -webkit-animation: movingleftright2 25000ms linear infinite;
    animation: movingleftright2 25000ms linear infinite;
}

/*------------------- 5.10 Why Choose Us -------------------*/
.why-choose-fluid {
    overflow: hidden;
}

.why-choose-fluid .why-choose-content {
    background-color: #615dfa;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: calc(100% - 585px);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

@media only screen and (max-width: 1199px) {
    .why-choose-fluid .why-choose-content {
        padding-left: calc(100% - 465px);
        padding-right: 30px;
    }
}

@media only screen and (max-width: 991px) {
    .why-choose-fluid .why-choose-content {
        padding: 80px calc((100% - 690px) / 2);
    }
}

@media only screen and (max-width: 767px) {
    .why-choose-fluid .why-choose-content {
        padding: 80px calc((100% - 510px) / 2);
    }
}

@media only screen and (max-width: 575px) {
    .why-choose-fluid .why-choose-content {
        padding: 80px 15px;
    }
}

.why-choose-fluid .why-choose-content:before {
    content: url("../../media/figure/shape_6.png");
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
}

.why-choose-fluid .why-choose-content:after {
    content: url("../../media/figure/multi_shape.png");
    position: absolute;
    top: 0;
    right: 35px;
    text-align: center;
    -webkit-animation: movinground 50000ms linear infinite;
    animation: movinground 50000ms linear infinite;
    z-index: -1;
}

.why-choose-fluid .content-box .item-title {
    color: #ffffff;
    margin-bottom: 15px;
}

.why-choose-fluid .content-box p {
    color: #ffffff;
    margin-bottom: 30px;
}

.why-choose-fluid .content-box .button-slide {
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 17px 21px 0 rgba(21, 17, 164, 0.3);
}

.why-choose-fluid .why-choose-img .image-box img {
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 991px) {
    .why-choose-fluid .why-choose-img .image-box img {
        height: auto;
    }
}

.why-choose-us {
    padding: 120px 0 70px;
}

@media only screen and (max-width: 1199px) {
    .why-choose-us {
        padding: 100px 0 50px;
    }
}

@media only screen and (max-width: 991px) {
    .why-choose-us {
        padding: 80px 0 30px;
    }
}

.why-choose-us .why-choose-box {
    margin-bottom: 50px;
}

.why-choose-us .why-choose-box .item-subtitle {
    color: #615dfa;
    font-weight: 600;
    margin-bottom: 15px;
}

.why-choose-us .why-choose-box .item-title {
    font-weight: 800;
    margin-bottom: 22px;
}

.why-choose-us .why-choose-box .item-title span {
    display: block;
}

.why-choose-us .why-choose-box p {
    color: #71728c;
    margin-bottom: 30px;
    width: 90%;
}

@media only screen and (max-width: 1199px) {
    .why-choose-us .why-choose-box p {
        width: 100%;
    }
}

.why-choose-us .why-choose-box .button-slide .btn-text {
    margin-left: 20px;
}

.why-choose-us .why-choose-box .button-slide .btn-icon {
    margin-left: 10px;
}

.why-choose-us .why-choose-box .features-list {
    position: relative;
}

.why-choose-us .why-choose-box .features-list:before {
    content: "";
    height: 90%;
    width: 50%;
    border: 9px solid #e1e1e1;
    border-radius: 50%;
    border-top: none;
    border-left: none;
    border-bottom: none;
    position: absolute;
    top: 50%;
    left: -23%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
}

@media only screen and (max-width: 1199px) {
    .why-choose-us .why-choose-box .features-list:before {
        left: -17%;
    }
}

@media only screen and (max-width: 991px) {
    .why-choose-us .why-choose-box .features-list:before {
        display: none;
    }
}

.why-choose-us .why-choose-box .features-list li {
    margin-bottom: 35px;
}

.why-choose-us .why-choose-box .features-list li:last-child {
    margin-bottom: 0;
}

.why-choose-us .why-choose-box .features-list li:nth-child(even) {
    padding-left: 80px;
}

@media only screen and (max-width: 991px) {
    .why-choose-us .why-choose-box .features-list li:nth-child(even) {
        padding-left: 0;
    }
}

.why-choose-us .why-choose-box .features-list .media:hover .item-icon {
    background-color: #615dfa;
    color: #ffffff;
}

.why-choose-us .why-choose-box .features-list .media:hover .media-body .item-title {
    color: #615dfa;
}

.why-choose-us .why-choose-box .features-list .item-icon {
    font-size: 50px;
    color: #b3b3b3;
    height: 130px;
    width: 130px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 31px 65px 0 rgba(153, 153, 153, 0.28);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 25px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .why-choose-us .why-choose-box .features-list .item-icon {
        height: 100px;
        width: 100px;
        font-size: 40px;
    }
}

.why-choose-us .why-choose-box .features-list .media-body .item-title {
    font-size: 22px;
    margin-bottom: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.why-choose-us .why-choose-box .features-list .media-body p {
    margin-bottom: 0;
}

/*------------------- 5.11 Login Page -------------------*/
.login-page-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: 100%;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap {
        height: 100%;
        position: inherit;
    }
}

.login-page-wrap .content-wrap {
    height: 100%;
    background-image: url("../../media/banner/hero_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow-y: auto;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .content-wrap {
        overflow: hidden;
    }
}

.login-page-wrap .content-wrap .map-line {
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.login-page-wrap .content-wrap .map-line .map-marker li {
    position: absolute;
}

.login-page-wrap .content-wrap .map-line .map-marker li:nth-child(1n) {
    bottom: 50px;
    right: 58%;
}

.login-page-wrap .content-wrap .map-line .map-marker li:nth-child(2n) {
    top: 33%;
    right: 75%;
}

.login-page-wrap .content-wrap .map-line .map-marker li:nth-child(3n) {
    top: 60%;
    right: 37%;
}

.login-page-wrap .content-wrap .map-line .map-marker li:nth-child(4n) {
    top: 38%;
    right: 21%;
}

.login-page-wrap .content-wrap .map-line .map-marker li:after {
    content: "";
    height: 6px;
    width: 33px;
    background-color: #403cd9;
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: -5px;
    -webkit-animation: pulse 1500ms infinite;
    animation: pulse 1500ms infinite;
}

.login-page-wrap .login-content {
    padding-top: 80px;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .login-content {
        padding: 50px 15px 0;
        width: 100%;
    }
}

.login-page-wrap .item-logo {
    margin-bottom: 50px;
    text-align: center;
}

.login-page-wrap .item-logo a {
    position: relative;
}

.login-page-wrap .item-logo a:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #5edfff;
    position: absolute;
    top: 7px;
    left: 68px;
    border-radius: 50%;
    -webkit-animation: pulse2 linear 1000ms infinite;
    animation: pulse2 linear 1000ms infinite;
}

.login-page-wrap .login-form-wrap {
    padding-bottom: 50px;
    position: relative;
    padding-left: 52px;
    z-index: 1;
    font-family: "Nunito", sans-serif;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .login-form-wrap {
        padding-left: 0;
    }
}

.login-page-wrap .nav-tabs {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    display: block;
    border-bottom: none;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .nav-tabs {
        position: inherit;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

.login-page-wrap .nav-tabs li {
    margin-bottom: 10px;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.login-page-wrap .nav-tabs li:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .nav-tabs li {
        -webkit-writing-mode: inherit;
        -ms-writing-mode: inherit;
        writing-mode: inherit;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-right: 10px;
    }
}

.login-page-wrap .nav-tabs li .nav-link {
    text-align: center;
    background-color: #ffffff;
    font-size: 16px;
    color: #000000;
    font-weight: 700;
    border: none;
    border-radius: 0 6px 6px 0;
    padding: 35px 12px;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .nav-tabs li .nav-link {
        padding: 12px 10px;
        border-radius: 6px 6px 0 0;
        font-size: 14px;
    }
}

.login-page-wrap .nav-tabs li .nav-link i {
    margin-bottom: 6px;
    display: inline-block;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 22px;
    color: #5edfff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .nav-tabs li .nav-link i {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-bottom: 0;
        margin-right: 6px;
        font-size: 18px;
    }
}

.login-page-wrap .nav-tabs li .nav-link:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.login-page-wrap .nav-tabs li .nav-link:hover i {
    color: #ffffff;
}

.login-page-wrap .nav-tabs li .nav-link.active {
    background-color: #5edfff;
    color: #ffffff;
}

.login-page-wrap .nav-tabs li .nav-link.active i {
    color: #ffffff;
}

.login-page-wrap .tab-content {
    min-width: 470px;
    background-color: #ffffff;
    border-radius: 0 15px 15px 15px;
    padding: 50px 60px;
    box-shadow: 0 19px 73px 0 rgba(0, 0, 0, 0.17);
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .tab-content {
        border-radius: 0 0 15px 15px;
        min-width: 100%;
        padding: 30px 20px;
    }
}

.login-page-wrap .tab-content .item-title {
    font-size: 24px;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 20px;
}

@media only screen and (max-width: 575px) {
    .login-page-wrap .tab-content .item-title {
        font-size: 22px;
    }
}

.login-page-wrap .tab-content .item-title span {
    display: block;
}

.login-page-wrap .tab-content .item-title:before {
    content: "";
    height: 3px;
    width: 8px;
    background-color: #615dfa;
    border-radius: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.login-page-wrap .tab-content .item-title:after {
    content: "";
    height: 3px;
    width: 18px;
    background-color: #615dfa;
    border-radius: 4px;
    position: absolute;
    left: 10px;
    bottom: 0;
}

.login-page-wrap .tab-content .form-group {
      /* input::-webkit-calendar-picker-indicator {
			 	
			 	&:before {
			 		color: red;
			 	}
			} */
}

.login-page-wrap .tab-content .form-group .form-control {
    border: none;
    border-radius: 0;
    padding: 5px 0;
    border-bottom: 1px solid #eaeaea;
}

.login-page-wrap .tab-content .form-group input.form-control::-webkit-input-placeholder,
      .login-page-wrap .tab-content .form-group textarea.form-control::-webkit-input-placeholder {
    color: #9f9f9f;
}

.login-page-wrap .tab-content .form-group input::-webkit-datetime-edit-text {
    color: #9f9f9f;
    padding: 0 0.3em;
}

.login-page-wrap .tab-content .form-group input::-webkit-datetime-edit-month-field {
    color: #9f9f9f;
}

.login-page-wrap .tab-content .form-group input::-webkit-datetime-edit-day-field {
    color: #9f9f9f;
}

.login-page-wrap .tab-content .form-group input::-webkit-datetime-edit-year-field {
    color: #9f9f9f;
}

.login-page-wrap .tab-content .form-group input::-webkit-inner-spin-button {
    display: none;
}

.login-page-wrap .tab-content .form-group .select2-container--classic .select2-selection--single {
    height: 50px;
    border: none;
    border-bottom: 1px solid #eaeaea;
}

.login-page-wrap .tab-content .form-group .select2-container--classic .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
}

.login-page-wrap .tab-content .form-group .select2-container--classic .select2-selection--single .select2-selection__arrow {
    height: 50px;
}

.login-page-wrap .tab-content .form-group .select2-container--classic .select2-selection--single .select2-selection__rendered {
    font-size: 16px;
    color: #9f9f9f;
    padding-left: 0;
}

.login-page-wrap .tab-content .form-group .submit-btn {
    width: 100%;
    box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.17);
    padding: 13px 10px;
}

.login-page-wrap .tab-content .login-tab .google-signin {
    text-align: center;
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 5px;
}

.login-page-wrap .tab-content .login-tab .google-signin:before {
    content: "or";
    font-size: 15px;
    color: #b6b6b6;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.login-page-wrap .tab-content .login-tab .google-signin a {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 12px;
    color: #646464;
    font-size: 15px;
    font-weight: 700;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-page-wrap .tab-content .login-tab .google-signin a img {
    margin-right: 8px;
}

.login-page-wrap .tab-content .login-tab .form-group .submit-btn {
    width: auto;
    padding: 12px 50px;
}

.login-page-wrap .tab-content .login-tab .reset-password {
    margin-bottom: 10px;
}

.login-page-wrap .tab-content .login-tab .reset-password a {
    display: block;
    text-align: right;
    color: #ff3d00;
    font-size: 15px;
    font-weight: 600;
}

.login-page-wrap .tab-content .login-tab .account-create {
    font-weight: 600;
    margin-top: 25px;
}

.login-page-wrap .tab-content .login-tab .account-create a {
    color: #615dfa;
    text-decoration: underline;
}

.login-page-wrap .tab-content .login-tab .account-create a:hover {
    color: #5edfff;
}

/*=======================================================================
6. Dashboard Style
=========================================================================*/
/*------------------- 6.1 Blocks -------------------*/
.block-box {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
}

/*------------------- 6.2 Banner -------------------*/
.newsfeed-banner {
    background-image: -webkit-linear-gradient(right, #ffea00, #ff9800);
    background-image: linear-gradient(to left, #ffea00, #ff9800);
    border-radius: 10px;
    padding: 60px 50px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    z-index: 1;
}

@media only screen and (max-width: 767px) {
    .newsfeed-banner {
        padding: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .newsfeed-banner {
        text-align: center;
        padding: 20px;
    }
}

.newsfeed-banner .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.newsfeed-banner .item-icon {
    font-size: 80px;
    color: #ffffff;
    margin-right: 55px;
    position: relative;
}

@media only screen and (max-width: 991px) {
    .newsfeed-banner .item-icon {
        font-size: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .newsfeed-banner .item-icon {
        font-size: 60px;
    }
}

@media only screen and (max-width: 575px) {
    .newsfeed-banner .item-icon {
        display: none;
    }
}

.newsfeed-banner .item-icon:before {
    content: "";
    height: 54px;
    width: 3px;
    background-color: #ffffff;
    border-radius: 2px;
    position: absolute;
    top: 46%;
    right: -35px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.newsfeed-banner .media-body .item-title {
    font-size: 30px;
    color: #ffffff;
    font-weight: 800;
    margin-bottom: 8px;
}

@media only screen and (max-width: 1199px) {
    .newsfeed-banner .media-body .item-title {
        font-size: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .newsfeed-banner .media-body .item-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 575px) {
    .newsfeed-banner .media-body .item-title {
        font-size: 24px;
    }
}

.newsfeed-banner .media-body p {
    margin-bottom: 0;
    color: #ffffff;
}

.newsfeed-banner .animation-img li {
    position: absolute;
    z-index: -1;
}

.newsfeed-banner .animation-img li:nth-child(1n) {
    top: 0;
    right: 150px;
}

.newsfeed-banner .animation-img li:nth-child(2n) {
    top: auto;
    bottom: 0;
    right: 30px;
}

@media only screen and (max-width: 991px) {
    .newsfeed-banner .animation-img li:nth-child(2n) {
        display: none;
    }
}

.banner-user {
    background-image: url("../../media/banner/banner_user.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 180px 35px 35px 50px;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

@media only screen and (max-width: 991px) {
    .banner-user {
        padding: 100px 35px 35px 50px;
    }
}

@media only screen and (max-width: 767px) {
    .banner-user {
        padding: 60px 30px 35px;
    }
}

@media only screen and (max-width: 767px) {
    .banner-user {
        padding: 60px 20px 35px;
    }
}

.banner-user:before {
    content: "";
    height: 100%;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.9), transparent);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    border-radius: 8px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.banner-user .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .banner-user .media {
        display: block;
        text-align: center;
    }
}

.banner-user .media .media-body {
    position: relative;
}

.banner-user .media .item-img {
    position: relative;
    margin-right: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    padding: 5px;
    display: inline-block;
}

@media only screen and (max-width: 575px) {
    .banner-user .media .item-img {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

.banner-user .media .item-img:after {
    content: url("../../media/banner/round_shape1.png");
    position: absolute;
    top: -9px;
    left: -8px;
}

.banner-user .media .item-img img {
    border-radius: 50%;
}

.banner-user .media .item-title {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 2px;
    position: relative;
    display: inline-block;
}

.banner-user .media .item-title:before {
    content: "\eed6";
    font-family: IcoFont;
    text-align: center;
    display: inline-block;
    height: 26px;
    width: 26px;
    line-height: 26px;
    background-color: #17dd17;
    border-radius: 50%;
    font-size: 20px;
    color: #ffffff;
    position: absolute;
    top: 1px;
    right: -38px;
}

.banner-user .media .item-subtitle {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
}

.banner-user .media .item-social li {
    display: inline-block;
    margin-right: 2px;
}

.banner-user .media .item-social li:last-child {
    margin-right: 0;
}

.banner-user .media .item-social li a {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.banner-user .media .item-social li a:hover {
    opacity: 0.8;
}

.banner-user .media .user-meta {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 991px) {
    .banner-user .media .user-meta {
        position: relative;
        margin-top: 20px;
    }
}

.banner-user .media .user-meta li {
    display: inline-block;
    color: #d7d7d7;
    margin-right: 24px;
}

.banner-user .media .user-meta li:last-child {
    margin-right: 0;
}

.banner-user .media .user-meta li span {
    font-weight: 600;
    color: #ffffff;
}

.banner-forum {
    background-image: url("../../media/banner/banner_forum.jpg");
}

.banner-forum .media .media-body .item-title:before {
    display: none;
}

.banner-forum .media .media-body .item-subtitle {
    color: #c2c2c2;
}

.banner-user-group {
    background-image: url("../../media/banner/banner_group.jpg");
}

.banner-user-group .media .media-body .item-title:before {
    display: none;
}

.banner-user-group .media .media-body .item-subtitle {
    color: #c2c2c2;
}

/*------------------- 6.3 Forum -------------------*/
.forum-list-table .table {
    background-color: #ffffff;
    border-radius: 8px;
}

.forum-list-table .table .thead-dark th {
    background-color: #615dfa;
    border-color: #ffffff;
    border-top: none;
    border-bottom: none;
    padding: 16px;
    font-weight: 600;
}

.forum-list-table .table .thead-dark th:first-child {
    border-radius: 8px 0 0 0;
    border-left: none;
    padding-left: 25px;
}

.forum-list-table .table .thead-dark th:last-child {
    border-radius: 0 8px 0 0;
    border-right: none;
    padding-left: 25px;
}

.forum-list-table .table td {
    border-bottom: none;
    border-color: #e7edf6;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
}

.forum-list-table .table td:first-child {
    border-left: none;
    padding-left: 25px;
}

.forum-list-table .table td:last-child {
    border-right: none;
    padding-left: 25px;
}

.forum-list-table .table .media {
    min-width: 220px;
    padding: 15px 0;
}

.forum-list-table .table .media .item-img {
    margin-right: 20px;
    margin-top: 5px;
}

.forum-list-table .table .media .item-img img {
    border-radius: 50%;
}

.forum-list-table .table .media .media-body .item-title {
    font-size: 16px;
    margin-bottom: 6px;
}

.forum-list-table .table .media .media-body .item-title a {
    color: #000000;
}

.forum-list-table .table .media .media-body .item-title a:hover {
    color: #615dfa;
}

.forum-list-table .table .media .media-body p {
    font-size: 14px;
    line-height: 22px;
}

.forum-list-table .table .forum-member {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.forum-list-table .table .forum-member .item-img {
    margin-top: 0;
}

.forum-list-table .table .forum-member .media-body .item-title {
    margin-bottom: 0;
    line-height: 22px;
}

.forum-list-table .table .forum-member .media-body .item-title a {
    color: #444444;
}

.forum-list-table .table .forum-member .media-body .item-time {
    font-size: 14px;
    line-height: 22px;
}

.forum-post-input .media {
    padding: 30px;
}

@media only screen and (max-width: 575px) {
    .forum-post-input .media {
        padding: 20px;
    }
}

.forum-post-input .media .item-img {
    margin-right: 15px;
    padding: 4px;
    position: relative;
}

.forum-post-input .media .item-img:after {
    content: url("../../media/figure/chat_round_shape3.png");
    position: absolute;
    top: 0;
    left: 0;
}

.forum-post-input .media .item-img img {
    border-radius: 50%;
}

.forum-post-input .media .media-body .form-control {
    border: none;
    background-color: #f4f7fc;
    border-radius: 4px;
    padding: 20px 30px;
    font-size: 14px;
}

.forum-post-input .media .media-body .form-control:focus {
    box-shadow: none;
}

.forum-post-input .media .media-body input.form-control::-webkit-input-placeholder,
  .forum-post-input .media .media-body textarea.form-control::-webkit-input-placeholder {
    color: #afb8c4;
}

.forum-post-input .post-footer .insert-btn a {
    margin-right: 5px !important;
}

.forum-last-status {
    margin-bottom: 35px;
}

.forum-last-status .widget-heading .widget-title {
    font-size: 24px;
}

@media only screen and (max-width: 991px) {
    .forum-last-status .widget-heading .widget-title {
        font-size: 20px;
    }
}

.forum-last-status .forum-topic-status {
    padding: 16px 25px;
}

.forum-last-status .forum-topic-status p {
    margin-bottom: 0;
    font-size: 14px;
    color: #aeadad;
}

.forum-last-status .forum-topic-status p a,
      .forum-last-status .forum-topic-status p span {
    color: #797979;
    text-decoration: underline;
}

.forum-last-status .forum-topic-status p a:hover {
    color: #615dfa;
}

.forum-last-status .forum-topic-status p i {
    margin: 0 5px;
    text-align: center;
    display: inline-block;
    height: 18px;
    width: 18px;
    line-height: 18px;
    background-color: #17dd17;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
}

.forum-last-status .subscribe-btn .item-btn {
    display: block;
    color: #898989;
    font-weight: 600;
    text-align: center;
    padding: 16px 10px;
    border-radius: 8px;
}

.forum-last-status .subscribe-btn .item-btn:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.forum-last-status .post-view .post-header .media .user-img {
    padding: 0;
}

.forum-last-status .post-view .post-header .media .user-img:after {
    display: none;
}

.forum-last-status .post-view .post-body p {
    background-color: #f5f7fa;
    padding: 20px 25px;
    border-radius: 4px;
    margin-bottom: 30px;
}

.forum-last-status .post-view .post-footer {
    border-top-color: #eff4fb;
    padding: 10px 30px;
}

.forum-last-status .post-view .post-footer ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.forum-last-status .post-view .post-footer ul li {
    font-size: 12px;
    color: #878787;
}

.forum-topic-add .heading-title {
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
    .forum-topic-add .heading-title {
        font-size: 20px;
    }
}

.forum-topic-add .block-box {
    padding: 35px 30px 45px;
}

.forum-topic-add .form-group {
    margin-bottom: 15px;
}

.forum-topic-add .form-group:last-child {
    margin-bottom: 0;
}

.forum-topic-add .form-group label {
    font-size: 14px;
    color: #c4c4c4;
}

.forum-topic-add .form-group .form-control {
    border-color: #e3e3e3;
}

.forum-topic-add .form-group .insert-btn a {
    font-size: 14px;
    color: #949494;
    padding: 0 8px;
    margin-bottom: 4px;
}

.forum-topic-add .form-group .insert-btn a:hover {
    color: #000000;
}

.forum-topic-add .form-group .submit-btn {
    margin-top: 10px;
    padding: 8px 32px;
}

.forum-info {
    padding: 20px 30px;
}

.forum-info .widget-heading {
    margin-bottom: 10px;
}

.forum-info p {
    line-height: 24px;
}

.user-list-view.forum-member .widget-author {
    padding: 30px;
}

@media only screen and (max-width: 991px) {
    .user-list-view.forum-member .widget-author {
        padding: 20px;
    }
}

.user-list-view.forum-member .widget-author .profile-img {
    margin-right: 25px;
}

@media only screen and (max-width: 991px) {
    .user-list-view.forum-member .widget-author .profile-img {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view.forum-member .widget-author .profile-img {
        margin-right: 10px;
    }
}

.user-list-view.forum-member .widget-author .cover-img {
    left: -6%;
}

.user-list-view.forum-member .widget-author .author-statistics li:last-child a {
    padding-right: 0;
}

.forum-media-status {
    padding: 20px 30px 10px;
}

.forum-media-status li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
}

.forum-media-status li:last-child {
    margin-right: 0;
}

.forum-media-status a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
}

.forum-media-status a span {
    color: #9e9faf;
    font-weight: 600;
    margin-left: 8px;
}

.forum-media-status a i {
    margin-right: 12px;
    height: 30px;
    width: 30px;
    background-color: #5edfff;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #ffffff;
}

.forum-media-gallery {
    padding: 25px 30px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media only screen and (max-width: 575px) {
    .forum-media-gallery {
        display: block;
    }
}

.forum-media-gallery .media-upload {
    margin-right: 100px;
}

@media only screen and (max-width: 767px) {
    .forum-media-gallery .media-upload {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .forum-media-gallery .media-upload {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.forum-media-gallery .media-upload .item-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
    .forum-media-gallery .media-upload .item-title {
        font-size: 20px;
    }
}

.forum-media-gallery .media-upload .upload-btn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 700;
    color: #ffffff;
    background-color: #a9b5c7;
    padding: 11px 30px;
    border-radius: 6px;
}

.forum-media-gallery .media-upload .upload-btn i {
    margin-right: 8px;
    font-weight: 400;
    font-size: 18px;
}

.forum-media-gallery .media-upload .upload-btn:hover {
    background-color: #5edfff;
}

.forum-media-gallery .input-group {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.forum-media-gallery .input-group .form-control {
    height: 50px;
    border: 1px solid #d5dae0;
    font-size: 16px;
}

.forum-media-gallery .input-group .input-group-append .search-btn {
    padding: 5px 16px;
    font-size: 20px;
}

/*------------------- 6.4 Post -------------------*/
.post-input-tab .nav-tabs {
    border-color: #e6e9ec;
}

.post-input-tab .nav-tabs .nav-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    border-right: 1px solid #e6e9ec;
    margin-bottom: 0;
}

.post-input-tab .nav-tabs .nav-item:last-child {
    border-right: none;
}

.post-input-tab .nav-tabs .nav-link {
    padding: 16px 10px;
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: #98a4b4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .post-input-tab .nav-tabs .nav-link {
        font-size: 12px;
    }
}

@media only screen and (max-width: 575px) {
    .post-input-tab .nav-tabs .nav-link {
        font-size: 0;
    }
}

.post-input-tab .nav-tabs .nav-link i {
    font-size: 20px;
    margin-right: 12px;
}

@media only screen and (max-width: 767px) {
    .post-input-tab .nav-tabs .nav-link i {
        font-size: 16px;
    }
}

.post-input-tab .nav-tabs .nav-link:after {
    content: "";
    height: 3px;
    width: 100%;
    background-color: #5edfff;
    position: absolute;
    left: 0;
    bottom: -1px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post-input-tab .nav-tabs .nav-link.active {
    color: #000000;
}

.post-input-tab .nav-tabs .nav-link.active:after {
    visibility: visible;
    opacity: 1;
}

.post-input-tab .tab-pane .form-control {
    border: none;
    padding: 16px 30px;
}

.post-input-tab .tab-pane .form-control:focus {
    outline: none;
    box-shadow: none;
}

.post-input-tab .tab-pane input.form-control::-webkit-input-placeholder,
.post-input-tab .tab-pane textarea.form-control::-webkit-input-placeholder {
    color: #afb8c4;
    font-size: 14px;
}

.post-input-tab .post-media {
    text-align: center;
    padding: 30px 0;
}

.post-input-tab .post-media label {
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #000000;
}

.post-input-tab .post-media .media-insert {
    font-weight: 700;
    color: #ffffff;
    background-color: #a9b5c7;
    border-radius: 4px;
    padding: 10px 32px;
}

.post-input-tab .post-media .media-insert i {
    font-size: 20px;
    margin-right: 10px;
}

.post-input-tab .post-media .media-insert:hover {
    background-color: #615dfa;
}

.post-input-tab .post-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #eaedf1;
    padding: 18px 20px 18px 30px;
}

@media only screen and (max-width: 479px) {
    .post-input-tab .post-footer {
        padding: 18px 20px;
    }
}

.post-input-tab .post-footer .insert-btn a {
    margin-right: 10px;
    color: #98a4b4;
    font-size: 24px;
    padding: 5px;
}

@media only screen and (max-width: 479px) {
    .post-input-tab .post-footer .insert-btn a {
        font-size: 20px;
        margin-right: 4px;
    }
}

.post-input-tab .post-footer .insert-btn a:last-child {
    margin-right: 0;
}

.post-input-tab .post-footer .insert-btn a:first-child {
    padding-left: 0;
}

.post-input-tab .post-footer .insert-btn a:hover {
    color: #615dfa;
}

.post-input-tab .post-footer .submit-btn a {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: #615dfa;
    border-radius: 4px;
    padding: 8px 32px;
}

@media only screen and (max-width: 479px) {
    .post-input-tab .post-footer .submit-btn a {
        padding: 7px 15px;
    }
}

.post-input-tab .post-footer .submit-btn a:hover {
    background-color: #5edfff;
}

.post-view .post-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 30px 30px 18px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-header {
        padding: 20px 15px 18px;
    }
}

.post-view .post-header .media .user-img {
    position: relative;
    margin-right: 14px;
    padding: 5px;
}

@media only screen and (max-width: 575px) {
    .post-view .post-header .media .user-img {
        margin-right: 5px;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.post-view .post-header .media .user-img:after {
    content: url("../../media/figure/chat_round_shape.png");
    position: absolute;
    top: 0;
    left: 0;
}

.post-view .post-header .media .user-img img {
    border-radius: 50%;
}

.post-view .post-header .media .user-title {
    font-weight: 600;
    color: #71728c;
    line-height: 25px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-header .media .user-title {
        font-size: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .post-view .post-header .media .user-title {
        font-size: 14px;
    }
}

.post-view .post-header .media .user-title a {
    color: #111111;
    font-weight: 700;
}

.post-view .post-header .media .user-title a:hover {
    color: #615dfa;
}

.post-view .post-header .media .user-title i {
    margin: 0 4px;
    text-align: center;
    display: inline-block;
    height: 21px;
    width: 21px;
    line-height: 21px;
    background-color: #17dd17;
    border-radius: 50%;
    font-size: 17px;
    color: #ffffff;
}

@media only screen and (max-width: 575px) {
    .post-view .post-header .media .user-title i {
        font-size: 15px;
        height: 16px;
        width: 16px;
        line-height: 16px;
    }
}

.post-view .post-header .media .user-title .badge {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    background-color: #615dfa;
    border-radius: 20px;
    padding: 4px 10px;
}

.post-view .post-header .media .entry-meta {
    line-height: 25px;
}

.post-view .post-header .media .entry-meta li {
    display: inline-block;
    color: #9e9faf;
    font-size: 12px;
    position: relative;
}

.post-view .post-header .media .entry-meta li:last-child:after {
    display: none;
}

.post-view .post-header .media .entry-meta li i {
    font-size: 14px;
    margin-right: 6px;
}

@media only screen and (max-width: 479px) {
    .post-view .post-header .media .entry-meta li i {
        margin-right: 4px;
    }
}

.post-view .post-header .media .entry-meta li:after {
    content: ".";
    color: #a5a6b5;
    font-size: 20px;
    padding: 0 2px 0 6px;
    font-weight: 700;
}

@media only screen and (max-width: 479px) {
    .post-view .post-header .media .entry-meta li:after {
        padding: 0 2px 0 5px;
    }
}

.post-view .post-header .dropdown {
    line-height: 1;
    margin-left: 10px;
}

.post-view .post-header .dropdown.show .dropdown-toggle {
    color: #ffffff;
}

.post-view .post-header .dropdown.show .dropdown-toggle:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-header .dropdown-toggle {
    background-color: transparent;
    padding-right: 0;
    border: none;
    font-size: 34px;
    color: #bbc5d3;
    margin-top: -15px;
    line-height: 1;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .post-view .post-header .dropdown-toggle {
        font-size: 30px;
    }
}

.post-view .post-header .dropdown-toggle:before {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #5edfff;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: -2px;
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .post-view .post-header .dropdown-toggle:before {
        height: 30px;
        width: 30px;
        top: 9px;
        left: 2px;
    }
}

.post-view .post-header .dropdown-toggle:after {
    display: none;
}

.post-view .post-header .dropdown-toggle:hover {
    color: #ffffff;
}

.post-view .post-header .dropdown-toggle:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-header .dropdown-menu {
    border: none;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    min-width: 130px;
    margin-top: 15px;
}

.post-view .post-header .dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 10px 20px;
}

.post-view .post-header .dropdown-menu .dropdown-item:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.post-view .post-body {
    padding: 0 30px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-body {
        padding: 0 15px;
    }
}

.post-view .post-body p {
    font-size: 14px;
    color: #71728c;
    margin-bottom: 20px;
}

.post-view .post-body p i {
    font-size: 16px;
    margin-left: 5px;
    color: #ff5722;
}

.post-view .post-body .post-img img {
    border-radius: 3px;
}

.post-view .post-body .post-meta-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 16px 0 18px;
}

.post-view .post-body .post-meta-wrap .post-meta .meta-text {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #71728c;
    margin-left: 12px;
}

@media only screen and (max-width: 479px) {
    .post-view .post-body .post-meta-wrap .post-meta .meta-text {
        margin-left: 5px;
        font-size: 13px;
    }
}

.post-view .post-body .post-meta-wrap .post-meta .meta-text:first-child {
    margin-left: 0;
}

.post-view .post-body .post-meta-wrap .post-meta .meta-text + .meta-text:before {
    content: ".";
    padding: 0 18px 0 2px;
}

@media only screen and (max-width: 479px) {
    .post-view .post-body .post-meta-wrap .post-meta .meta-text + .meta-text:before {
        padding: 0 10px 0 2px;
    }
}

.post-view .post-body .post-meta-wrap .post-reaction {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 2px;
}

.post-view .post-body .post-meta-wrap .post-reaction img {
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-right: -10px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
}

@media only screen and (max-width: 479px) {
    .post-view .post-body .post-meta-wrap .post-reaction img {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        margin-right: -18px;
    }
}

.post-view .post-body .post-meta-wrap .post-reaction img:last-child {
    margin-right: 0;
}

.post-view .post-body .post-friends-view {
    padding: 12px 0 35px;
}

.post-view .post-body .post-friends-view p {
    margin-bottom: 10px;
}

.post-view .post-body .post-friends-view .profile-thumb {
    border: 1px solid #ebebeb;
    border-radius: 3px;
}

.post-view .post-body .post-friends-view .cover-img img {
    border-radius: 3px 3px 0 0;
}

.post-view .post-body .post-friends-view .media {
    padding-bottom: 20px;
    padding-left: 25px;
}

.post-view .post-body .post-friends-view .media .media-body {
    padding-top: 18px;
}

.post-view .post-body .post-friends-view .profile-img {
    margin-top: -46px;
    margin-right: 18px;
}

.post-view .post-body .post-friends-view .profile-img img {
    border-radius: 50%;
    border: 8px solid #ffffff;
}

.post-view .post-body .post-friends-view .profile-name {
    font-weight: 700;
    line-height: 22px;
}

.post-view .post-body .post-friends-view .profile-name a {
    color: #111111;
}

.post-view .post-body .post-friends-view .profile-name a:hover {
    color: #615dfa;
}

.post-view .post-body .post-friends-view .user-name {
    line-height: 22px;
    font-size: 14px;
    color: #aeacac;
}

.post-view .post-body .post-img-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
}

.post-view .post-body .post-img-list li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    margin-bottom: 8px;
}

.post-view .post-body .post-img-list li a {
    border-radius: 6px;
    display: block;
    position: relative;
    text-align: center;
}

.post-view .post-body .post-img-list li img {
    border-radius: 6px;
}

.post-view .post-body .post-img-list li:nth-child(4n) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.post-view .post-body .post-img-list li:last-child a:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(97, 93, 250, 0.9);
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.post-view .post-body .post-img-list li:last-child a:after {
    content: attr(data-photo);
    font-size: 36px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}

@media only screen and (max-width: 991px) {
    .post-view .post-body .post-img-list li:last-child a:after {
        font-size: 32px;
    }
}

@media only screen and (max-width: 767px) {
    .post-view .post-body .post-img-list li:last-child a:after {
        font-size: 28px;
    }
}

@media only screen and (max-width: 575px) {
    .post-view .post-body .post-img-list li:last-child a:after {
        font-size: 24px;
    }
}

@media only screen and (max-width: 479px) {
    .post-view .post-body .post-img-list li:last-child a:after {
        font-size: 20px;
    }
}

.post-view .post-body .post-video {
    position: relative;
    text-align: center;
}

.post-view .post-body .post-video img {
    border-radius: 3px;
}

.post-view .post-body .post-video:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.post-view .post-body .post-video .video-btn {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    height: 70px;
    width: 70px;
    line-height: 60px;
    font-size: 24px;
    color: #ffffff;
    background-color: #615dfa;
    border: 5px solid #ffffff;
    border-radius: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 991px) {
    .post-view .post-body .post-video .video-btn {
        height: 60px;
        width: 60px;
        line-height: 55px;
        font-size: 22px;
        border-width: 3px;
    }
}

@media only screen and (max-width: 575px) {
    .post-view .post-body .post-video .video-btn {
        height: 50px;
        width: 50px;
        line-height: 45px;
        font-size: 18px;
        border-width: 3px;
    }
}

.post-view .post-body .post-video .video-btn i {
    margin-left: 5px;
}

.post-view .post-body .post-video .video-btn:hover {
    background-color: #5edfff;
}

.post-view .post-body .post-no-thumbnail {
    border-bottom: 1px solid #e6e9ec;
    padding-bottom: 28px;
}

.post-view .post-body .post-no-thumbnail p {
    margin-bottom: 14px;
}

.post-view .post-footer {
    padding: 3px 30px;
    border-top: 1px solid #e6e9ec;
}

@media only screen and (max-width: 767px) {
    .post-view .post-footer {
        padding: 3px 15px;
    }
}

.post-view .post-footer > ul {
    position: relative;
}

@media only screen and (max-width: 479px) {
    .post-view .post-footer > ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.post-view .post-footer > ul > li {
    display: inline-block;
    margin-right: 55px;
}

@media only screen and (max-width: 575px) {
    .post-view .post-footer > ul > li {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .post-view .post-footer > ul > li {
        margin-right: 10px;
    }
}

.post-view .post-footer > ul > li:last-child {
    margin-right: 0;
}

.post-view .post-footer > ul > li > a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    color: #b1b6bc;
    font-weight: 600;
    padding: 15px 0;
}

.post-view .post-footer > ul > li > a i {
    font-size: 18px;
    color: #71728c;
    margin-right: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .post-view .post-footer > ul > li > a i {
        margin-right: 10px;
        font-size: 15px;
    }
}

.post-view .post-footer > ul > li > a:hover {
    color: #5edfff;
}

.post-view .post-footer > ul > li > a:hover i {
    color: #5edfff;
}

.post-view .post-footer .post-react .react-list {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 7px 15px 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post-view .post-footer .post-react .react-list li {
    display: inline-block;
    margin-right: 4px;
    opacity: 0;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .post-view .post-footer .post-react .react-list li {
        margin-right: 0;
    }
}

.post-view .post-footer .post-react .react-list li:last-child {
    margin-right: 0;
}

.post-view .post-footer .post-react .react-list li a img {
    border-radius: 50%;
    border: 4px solid #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .post-view .post-footer .post-react .react-list li a img {
        border: 1px solid #ffffff;
    }
}

.post-view .post-footer .post-react .react-list li a:hover img {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.post-view .post-footer .post-react:hover .react-list {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-footer .post-react:hover .react-list li {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(1n) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(2n) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(3n) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(4n) {
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(5n) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(6n) {
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
}

.post-view .post-footer .post-react:hover .react-list li:nth-child(7n) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.post-view .post-footer .post-share {
    position: relative;
}

.post-view .post-footer .post-share .share-list {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 479px) {
    .post-view .post-footer .post-share .share-list {
        right: 0;
        left: auto;
    }
}

.post-view .post-footer .post-share .share-list li {
    display: inline-block;
    opacity: 0;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post-view .post-footer .post-share .share-list li a {
    text-align: center;
    font-size: 18px;
    padding: 8px 8px 5px;
    position: relative;
    z-index: 1;
}

.post-view .post-footer .post-share .share-list li a:before {
    content: "";
    height: 35px;
    width: 35px;
    background-color: #5edfff;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: -1px;
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post-view .post-footer .post-share .share-list li a:hover {
    color: #ffffff;
}

.post-view .post-footer .post-share .share-list li a:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-footer .post-share:hover .share-list {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.post-view .post-footer .post-share:hover .share-list li {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.post-view .post-footer .post-share:hover .share-list li:nth-child(2n) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.post-view .post-footer .post-share:hover .share-list li:nth-child(3n) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.post-view .post-footer .post-share:hover .share-list li:nth-child(4n) {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

.post-view .post-footer .post-share:hover .share-list li:nth-child(5n) {
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

.post-view .post-comment {
    border-top: 1px solid #e6e9ec;
}

.post-view .post-comment .comment-list {
    border-bottom: 1px solid #e6e9ec;
    padding: 0 30px 0 50px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-comment .comment-list {
        padding: 0 20px 0;
    }
}

.post-view .post-comment .main-comments {
    border-bottom: 1px solid #e6e9ec;
}

.post-view .post-comment .main-comments:last-child {
    border-bottom: none;
}

.post-view .post-comment .post-header {
    padding-left: 0;
    padding-right: 0;
}

.post-view .post-comment .post-header .media .user-img:after {
    content: url("../../media/figure/chat_round_shape3.png");
    top: 1px;
    left: 1px;
}

.post-view .post-comment .post-body {
    padding-left: 0;
    padding-right: 0;
}

.post-view .post-comment .post-body p:last-child {
    margin-bottom: 0;
}

.post-view .post-comment .post-footer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    border-top: none;
}

.post-view .post-comment .post-footer ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.post-view .post-comment .post-footer li {
    margin-right: 30px;
}

.post-view .post-comment .post-footer .react-icon img {
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
    margin-right: -10px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-comment .post-footer .react-icon img {
        margin-right: -18px;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.post-view .post-comment .load-more-btn {
    border-bottom: 1px solid #e6e9ec;
}

.post-view .post-comment .load-more-btn .item-btn {
    font-size: 14px;
    font-weight: 700;
    color: #71728c;
    border-radius: 0;
    padding: 16px 10px;
}

.post-view .post-comment .load-more-btn .item-btn span {
    color: #615dfa;
    margin-left: 5px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.post-view .post-comment .load-more-btn .item-btn:hover {
    color: #ffffff;
}

.post-view .post-comment .load-more-btn .item-btn:hover span {
    color: #ffffff;
}

.post-view .post-comment .comment-reply {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 30px;
}

@media only screen and (max-width: 767px) {
    .post-view .post-comment .comment-reply {
        padding: 10px 20px;
    }
}

.post-view .post-comment .comment-reply .user-img {
    border-radius: 50%;
    padding: 5px;
    position: relative;
    margin-right: 15px;
}

.post-view .post-comment .comment-reply .user-img:after {
    content: url("../../media/figure/chat_round_shape3.png");
    position: absolute;
    top: 1px;
    left: 1px;
}

.post-view .post-comment .comment-reply .user-img img {
    border-radius: 50%;
}

.post-view .post-comment .comment-reply .input-box {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.post-view .post-comment .comment-reply .input-box .form-control {
    border: 1px solid #e6e9ec;
    padding: 10px 20px;
    color: #111111;
    font-size: 14px;
    height: 54px;
    border-radius: 8px;
}

.post-view .post-comment .comment-reply .input-box .form-control:focus {
    outline: none;
    box-shadow: none;
}

.post-view .post-comment .comment-reply .input-box input.form-control::-webkit-input-placeholder,
      .post-view .post-comment .comment-reply .input-box textarea.form-control::-webkit-input-placeholder {
    color: #b1b6bc;
}

.post-view .post-comment .children {
    padding-left: 30px;
    border-top: 1px solid #e6e9ec;
}

@media only screen and (max-width: 767px) {
    .post-view .post-comment .children {
        padding-left: 15px;
    }
}

.load-more-btn .item-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    color: #000000;
    padding: 22px 10px;
    border-radius: 8px;
}

@media only screen and (max-width: 767px) {
    .load-more-btn .item-btn {
        padding: 20px 10px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .load-more-btn .item-btn {
        padding: 15px 10px;
    }
}

.load-more-btn .item-btn i {
    font-size: 18px;
    margin-right: 10px;
    color: #615dfa;
    font-weight: 700;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .load-more-btn .item-btn i {
        font-size: 16px;
    }
}

.load-more-btn .item-btn:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.load-more-btn .item-btn:hover i {
    color: #ffffff;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/*------------------- 6.5 Product -------------------*/
.product-page .block-box {
    margin-bottom: 30px;
}

.product-page .load-more-btn {
    margin-bottom: 80px;
}

.product-breadcrumb {
    padding: 130px 0;
    background-image: url("../../media/figure/product_breadcrum.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 1199px) {
    .product-breadcrumb {
        padding: 100px 0;
    }
}

@media only screen and (max-width: 991px) {
    .product-breadcrumb {
        padding: 80px 0;
    }
}

@media only screen and (max-width: 767px) {
    .product-breadcrumb {
        padding: 60px 0;
    }
}

.product-breadcrumb .breadcrumb-area {
    position: relative;
    z-index: 1;
    text-align: center;
}

.product-breadcrumb .breadcrumb-area h1 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 0;
}

.product-breadcrumb .breadcrumb-area ul li {
    display: inline-block;
    color: #ffffff;
}

.product-breadcrumb .breadcrumb-area ul li:last-child:after {
    display: none;
}

.product-breadcrumb .breadcrumb-area ul li:after {
    content: "/";
    padding: 0 2px;
}

.product-breadcrumb .breadcrumb-area ul li a {
    font-weight: 600;
    color: #decff6;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.product-breadcrumb .breadcrumb-area ul li a:hover {
    color: #5edfff;
}

.product-filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 25px;
}

@media only screen and (max-width: 991px) {
    .product-filter {
        display: block;
    }
}

.product-filter label {
    margin-bottom: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}

.product-filter .select-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media only screen and (max-width: 767px) {
    .product-filter .select-box {
        display: block;
    }
}

.product-filter .select-box .select2 {
    margin-right: 20px;
    min-width: auto !important;
}

@media only screen and (max-width: 991px) {
    .product-filter .select-box .select2 {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .product-filter .select-box .select2 {
        margin-right: 0;
    }
}

.product-filter .filter-btn .item-btn {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: #615dfa;
    border-radius: 4px;
    padding: 6px 60px;
}

.product-filter .filter-btn .item-btn:hover {
    background-color: #5edfff;
}

.product-box {
    padding: 30px 40px 22px;
}

.product-box .product-img {
    text-align: center;
    margin-bottom: 45px;
}

.product-box .product-img a {
    display: block;
}

.product-box .product-img a img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.product-box .product-content {
    position: relative;
}

.product-box .product-content .item-category {
    margin-bottom: 4px;
}

.product-box .product-content .item-category a {
    font-size: 12px;
    color: #828282;
    letter-spacing: 2px;
    font-weight: 600;
}

.product-box .product-content .item-category a:hover {
    color: #615dfa;
}

.product-box .product-content .product-title {
    font-size: 18px;
}

.product-box .product-content .product-title a {
    color: #000000;
}

.product-box .product-content .product-title a:hover {
    color: #615dfa;
}

.product-box .product-content .product-price {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 18px;
    font-weight: 700;
    color: #615dfa;
}

.product-box:hover .product-img a img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.single-product {
    margin-top: 40px;
    margin-bottom: 45px;
}

@media only screen and (max-width: 991px) {
    .single-product .product-gallery {
        margin-bottom: 30px;
    }
}

.single-product .product-gallery .tab-content {
    position: relative;
    border: none;
    overflow: hidden;
}

.single-product .product-gallery .tab-content a {
    cursor: move;
    display: block;
    background-color: #ffffff;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;
}

.single-product .product-gallery .tab-content a img {
    border-radius: 8px;
}

.single-product .product-gallery .nav-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    border-bottom: none;
}

.single-product .product-gallery .nav-tabs .nav-item {
    max-width: 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.single-product .product-gallery .nav-tabs .nav-item a {
    display: block;
    border-radius: 6px;
    background-color: #ffffff;
    text-align: center;
    padding: 12px 10px;
}

.single-product .product-gallery .nav-tabs .nav-item a img {
    opacity: 0.2;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.single-product .product-gallery .nav-tabs .nav-item a.active img {
    opacity: 1;
}

.single-product .product-content .item-category {
    font-size: 12px;
    color: #828282;
    font-weight: 600;
    letter-spacing: 2px;
}

.single-product .product-content .item-title {
    font-size: 24px;
    margin-bottom: 15px;
}

.single-product .product-content .item-price {
    font-size: 24px;
    font-weight: 700;
    color: #615dfa;
    margin-bottom: 20px;
}

.single-product .product-content .entry-meta {
    margin-bottom: 25px;
}

.single-product .product-content .entry-meta > li {
    display: block;
    color: #000000;
    font-weight: 700;
}

.single-product .product-content .entry-meta > li span {
    color: #71728c;
    font-weight: 600;
}

.single-product .product-content .entry-meta > li a {
    color: #71728c;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.single-product .product-content .entry-meta > li a:hover {
    color: #615dfa;
}

.single-product .product-content p {
    font-size: 14px;
    color: #71728c;
    line-height: 26px;
}

.single-product .product-content .action-area {
    margin-top: 110px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1199px) {
    .single-product .product-content .action-area {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 575px) {
    .single-product .product-content .action-area {
        display: block;
    }
}

.single-product .product-content .action-area li {
    margin-right: 5px;
}

@media only screen and (max-width: 575px) {
    .single-product .product-content .action-area li {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.single-product .product-content .action-area li:last-child {
    margin-right: 0;
}

.single-product .product-content .action-area li .input-group {
    background-color: transparent;
}

.single-product .product-content .action-area li .input-group .form-control {
    text-align: center;
    padding: 5px;
    height: 46px;
    width: 70px;
    display: block;
    outline: none;
    color: #71728c;
    background-color: #ffffff;
    font-size: 16px;
    border: none;
    margin: 0 4px;
    border-radius: 4px;
}

.single-product .product-content .action-area li .input-group .form-control:focus {
    box-shadow: none;
}

.single-product .product-content .action-area li .input-group .quantity-btn {
    padding: 5px;
    height: 46px;
    width: 50px;
    font-size: 14px;
    line-height: 1;
    color: #5edfff;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.single-product .product-content .action-area li .cart-btn {
    background-color: #615dfa;
    border-radius: 4px;
    padding: 9px 30px;
    display: block;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.single-product .product-content .action-area li .cart-btn:hover {
    background-color: #5edfff;
}

.single-product-info {
    background-color: #ffffff;
    padding: 30px 35px;
    border-radius: 8px;
    margin-bottom: 45px;
}

@media only screen and (max-width: 767px) {
    .single-product-info {
        padding: 30px 15px;
    }
}

.single-product-info .nav-tabs {
    border-bottom: none;
    margin-bottom: 25px;
}

@media only screen and (max-width: 575px) {
    .single-product-info .nav-tabs {
        margin-bottom: 10px;
    }
}

.single-product-info .nav-tabs .nav-item {
    margin-right: 40px;
}

@media only screen and (max-width: 575px) {
    .single-product-info .nav-tabs .nav-item {
        margin-right: 15px;
        margin-bottom: 10px;
    }
}

.single-product-info .nav-tabs .nav-item .nav-link {
    position: relative;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: #a4a4a4;
    padding: 0 0 14px;
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
    .single-product-info .nav-tabs .nav-item .nav-link {
        font-size: 15px;
    }
}

.single-product-info .nav-tabs .nav-item .nav-link:after {
    content: "";
    height: 3px;
    width: 100%;
    background-color: #615dfa;
    border-radius: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.single-product-info .nav-tabs .nav-item a.active {
    color: #000000;
}

.single-product-info .nav-tabs .nav-item a.active:after {
    visibility: visible;
    opacity: 1;
}

.single-product-info .tab-content .product-description p {
    font-size: 14px;
    color: #71728c;
}

.single-product-info .tab-content .additional-info li {
    font-size: 18px;
    color: #000000;
    margin-bottom: 5px;
}

@media only screen and (max-width: 991px) {
    .single-product-info .tab-content .additional-info li {
        font-size: 15px;
    }
}

.single-product-info .tab-content .additional-info li:before {
    content: "\eed8";
    font-family: IcoFont;
    color: #615dfa;
    font-size: 20px;
    padding-right: 10px;
}

.single-product-info .tab-content .product-review {
    margin-bottom: 40px;
}

.single-product-info .tab-content .product-review .media {
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 25px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 479px) {
    .single-product-info .tab-content .product-review .media {
        display: block;
    }
}

.single-product-info .tab-content .product-review .media:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.single-product-info .tab-content .product-review .media .item-img {
    margin-top: 5px;
    margin-right: 20px;
}

@media only screen and (max-width: 479px) {
    .single-product-info .tab-content .product-review .media .item-img {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 15px;
    }
}

.single-product-info .tab-content .product-review .media .item-img img {
    border-radius: 50%;
}

.single-product-info .tab-content .product-review .media .media-body .item-date {
    font-size: 14px;
    font-weight: 600;
    color: #615dfa;
}

.single-product-info .tab-content .product-review .media .media-body .author-name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.single-product-info .tab-content .product-review .media .media-body .author-name .item-title {
    font-size: 18px;
    margin-bottom: 6px;
}

.single-product-info .tab-content .product-review .media .media-body .author-name .item-rating {
    margin-left: 10px;
}

.single-product-info .tab-content .product-review .media .media-body .author-name .item-rating i {
    font-size: 14px;
    color: #f7b035;
}

.single-product-info .tab-content .product-review .media .media-body p {
    width: 90%;
    margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
    .single-product-info .tab-content .product-review .media .media-body p {
        width: 100%;
    }
}

.single-product-info .tab-content .review-form .heading-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.related-product .heading-title {
    font-size: 24px;
    margin-bottom: 26px;
}

/*------------------- 6.6 Search -------------------*/
.newsfeed-search {
    background-color: #ffffff;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 30px;
}

.newsfeed-search ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.newsfeed-search .active-member {
    padding-left: 20px;
}

.newsfeed-search .active-member a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 479px) {
    .newsfeed-search .active-member a {
        font-size: 14px;
    }
}

.newsfeed-search .active-member a .member-icon {
    font-size: 18px;
    color: #615dfa;
    height: 40px;
    width: 40px;
    background-color: #eff4fb;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
}

@media only screen and (max-width: 479px) {
    .newsfeed-search .active-member a .member-icon {
        display: none;
    }
}

.newsfeed-search .active-member a .member-text {
    color: #646464;
    font-weight: 600;
}

.newsfeed-search .active-member a .member-count {
    margin-left: 5px;
    font-weight: 700;
    color: #000000;
}

.newsfeed-search .search-list li {
    border-left: 1px solid #e4e9f0;
    position: relative;
}

.newsfeed-search .search-list li .drop-btn {
    padding: 21px 25px;
    font-size: 20px;
    color: #7d8693;
    font-weight: 700;
    border: none;
    background-color: transparent;
}

@media only screen and (max-width: 479px) {
    .newsfeed-search .search-list li .drop-btn {
        padding: 15px;
        font-size: 18px;
    }
}

.newsfeed-search .search-list li .drop-btn:after {
    display: none;
}

.newsfeed-search .search-list li .drop-menu {
    position: absolute;
    top: 100%;
    right: -71px;
    visibility: hidden;
    opacity: 0;
    background-color: #f8f8f8;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 11;
}

@media only screen and (max-width: 479px) {
    .newsfeed-search .search-list li .drop-menu {
        right: -50px;
    }
}

.newsfeed-search .search-list li .drop-menu.show {
    top: 98%;
    visibility: visible;
    opacity: 1;
}

.newsfeed-search .search-list .search-input .drop-menu {
    right: 0;
}

.newsfeed-search .search-list .search-input .input-group {
    min-width: 250px;
}

.user-search-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 22px 25px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
    .user-search-bar {
        display: block;
        padding: 15px;
    }
}

.user-search-bar .box-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 30px;
}

@media only screen and (max-width: 575px) {
    .user-search-bar .box-item {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-right: 0;
    }
}

.user-search-bar .box-item:last-child {
    margin-right: 0;
}

.user-search-bar .box-item .item-show-title {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}

.user-search-bar .search-box {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media only screen and (max-width: 575px) {
    .user-search-bar .search-box {
        margin-bottom: 14px;
    }
}

.user-search-bar .search-filter {
    margin-right: 14px;
}

@media only screen and (max-width: 575px) {
    .user-search-bar .search-filter {
        margin-right: 0;
    }
}

.user-search-bar .input-group {
    border-radius: 5px;
}

.user-search-bar .input-group .form-control {
    border: 1px solid #e1e1e1;
    border-radius: 5px 0 0 5px;
    height: 46px;
    font-size: 14px;
}

.user-search-bar .input-group .search-btn {
    font-size: 18px;
    padding: 8px 18px;
    background-color: #5edfff;
}

.user-search-bar .input-group .search-btn:hover {
    background-color: #615dfa;
}

.user-search-bar .dropdown label {
    font-size: 14px;
    color: #9e9faf;
    margin-bottom: 0;
    font-weight: 600;
}

.user-search-bar .dropdown .dropdown-toggle {
    font-size: 14px;
    font-weight: 600;
    border: none;
    background-color: transparent;
}

.user-search-bar .dropdown .dropdown-toggle:after {
    border: none;
    content: "\ea99";
    font-family: IcoFont;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.user-search-bar .dropdown .dropdown-menu {
    border: none;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    top: 0 !important;
}

.user-search-bar .dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 5px 20px;
}

.user-search-bar .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.user-search-bar .dropdown.show .dropdown-toggle:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.user-search-bar .user-view-switcher li {
    display: inline-block;
}

.user-search-bar .user-view-switcher li .user-view-trigger {
    font-size: 24px;
    color: #646464;
    padding: 7px;
}

.user-search-bar .user-view-switcher li:last-child a {
    padding-right: 0;
}

.user-search-bar .user-view-switcher li.active a {
    color: #615dfa;
}

/*------------------- 6.7 User Activity -------------------*/
.user-top-header {
    margin-bottom: 30px;
}

.user-top-header .menu-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 3px solid #d8e1ef;
    border-radius: 0 0 8px 8px;
}

.user-top-header .menu-list li {
    display: inline-block;
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 10.5%;
    -ms-flex: 0 0 10.5%;
    flex: 0 0 10.5%;
    max-width: 10.5%;
    width: 100%;
    text-align: center;
    border-right: 1px solid #e4e9f1;
}

@media only screen and (max-width: 767px) {
    .user-top-header .menu-list li {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        display: none;
    }
}

.user-top-header .menu-list li:after {
    content: "";
    height: 5px;
    width: 100%;
    background-color: #615dfa;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    visibility: hidden;
    opacity: 0;
}

.user-top-header .menu-list li:nth-child(-n+3) {
    display: block;
}

.user-top-header .menu-list li:last-child {
    border-right: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 5.5%;
    -ms-flex: 0 0 5.5%;
    flex: 0 0 5.5%;
    max-width: 5.5%;
    display: block;
}

.user-top-header .menu-list li:last-child:after {
    border-radius: 0 0 8px 0;
}

.user-top-header .menu-list li:first-child:after {
    border-radius: 0 0 0 8px;
}

.user-top-header .menu-list li a {
    font-size: 14px;
    color: #9e9faf;
    font-weight: 700;
    padding: 20px;
    width: 100%;
}

@media only screen and (max-width: 1199px) {
    .user-top-header .menu-list li a {
        font-size: 13px;
        padding: 15px 10px;
    }
}

.user-top-header .menu-list li a:hover {
    color: #000000;
}

.user-top-header .menu-list li.active:after {
    visibility: visible;
    opacity: 1;
}

.user-top-header .menu-list li.active a {
    font-weight: 700;
    color: #000000;
}

.user-top-header .menu-list .dropdown {
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .user-top-header .menu-list .dropdown {
        margin-left: 15px;
    }
}

.user-top-header .menu-list .dropdown .dropdown-toggle {
    height: 100%;
    margin-top: -8px;
    background-color: transparent;
    border: none;
    font-size: 34px;
    color: #bbc5d3;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.user-top-header .menu-list .dropdown .dropdown-toggle:before {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #5edfff;
    border-radius: 50%;
    position: absolute;
    top: 23px;
    left: -2px;
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
    .user-top-header .menu-list .dropdown .dropdown-toggle:before {
        height: 35px;
        width: 35px;
        top: 20px;
        left: -1px;
    }
}

.user-top-header .menu-list .dropdown .dropdown-toggle:after {
    display: none;
}

.user-top-header .menu-list .dropdown .dropdown-toggle:hover {
    color: #ffffff;
}

.user-top-header .menu-list .dropdown .dropdown-toggle:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.user-top-header .menu-list .dropdown .dropdown-menu {
    top: 6px !important;
    left: 15px !important;
    border: none;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
}

.user-top-header .menu-list .dropdown .dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 5px 20px;
}

.user-top-header .menu-list .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.user-top-header .menu-list .dropdown.show .dropdown-toggle {
    color: #ffffff;
}

.user-top-header .menu-list .dropdown.show .dropdown-toggle:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.user-timeline-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 30px;
}

@media only screen and (max-width: 767px) {
    .user-timeline-header {
        padding: 15px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .user-timeline-header {
        display: block;
    }
}

.user-timeline-header .menu-list li {
    display: inline-block;
    margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
    .user-timeline-header .menu-list li {
        margin-right: 15px;
    }
}

.user-timeline-header .menu-list li a {
    font-weight: 700;
    color: #98a4b4;
    position: relative;
    padding: 21px 0;
}

@media only screen and (max-width: 1199px) {
    .user-timeline-header .menu-list li a {
        font-size: 13px;
    }
}

.user-timeline-header .menu-list li a:before {
    content: "";
    height: 4px;
    width: 100%;
    background-color: #5edfff;
    position: absolute;
    left: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
}

.user-timeline-header .menu-list li a:hover {
    color: #000000;
}

.user-timeline-header .menu-list li a.active {
    color: #000000;
}

.user-timeline-header .menu-list li a.active:before {
    visibility: visible;
    opacity: 1;
}

.user-timeline-header .header-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.user-timeline-header .header-dropdown:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
    .user-timeline-header .header-dropdown {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 10px;
    }
}

.user-timeline-header .header-dropdown label {
    margin-bottom: 0;
    margin-right: 12px;
    font-weight: 700;
    color: #98a4b4;
}

@media only screen and (max-width: 1199px) {
    .user-timeline-header .header-dropdown label {
        font-size: 14px;
    }
}

.user-timeline-header .header-dropdown .dropdown-toggle {
    background-color: transparent;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 700;
    padding: 0;
}

@media only screen and (max-width: 1199px) {
    .user-timeline-header .header-dropdown .dropdown-toggle {
        font-size: 14px;
    }
}

.user-timeline-header .header-dropdown .dropdown-toggle:after {
    content: "\ea99";
    font-family: IcoFont;
    margin-top: 2px;
    margin-left: 8px;
    border: none;
    font-weight: 500;
}

.user-timeline-header .header-dropdown .dropdown-menu {
    border: none;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    top: 15px !important;
}

.user-timeline-header .header-dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    color: #000000;
}

.user-timeline-header .header-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.user-about {
    padding: 26px 30px;
}

.user-about .widget-heading {
    margin-bottom: 10px;
}

.user-about .user-info li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #e6ebf2;
    margin-bottom: 12px;
    padding-bottom: 2px;
}

@media only screen and (max-width: 479px) {
    .user-about .user-info li {
        display: block;
    }
}

.user-about .user-info li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.user-about .user-info li label {
    min-width: 240px;
    margin-right: 10px;
    color: #71728c;
}

@media only screen and (max-width: 767px) {
    .user-about .user-info li label {
        min-width: 140px;
    }
}

@media only screen and (max-width: 479px) {
    .user-about .user-info li label {
        min-width: auto;
        margin-right: 0;
        font-weight: 700;
        color: #000000;
    }
}

.user-about .user-info li a {
    color: #5edfff;
}

.user-about .user-info li a:hover {
    color: #615dfa;
}

#user-view .widget-author {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
}

#user-view .widget-author .author-name {
    line-height: 20px;
}

.user-list-view .row > [class^="col-"] {
    max-width: 100% !important;
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
}

.user-list-view .widget-author {
    padding: 30px 80px 30px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
}

@media only screen and (max-width: 991px) {
    .user-list-view .widget-author {
        padding: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author {
        display: block;
    }
}

.user-list-view .widget-author .author-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author .author-heading {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.user-list-view .widget-author .profile-img {
    margin-bottom: 0;
    margin-right: 30px;
}

@media only screen and (max-width: 991px) {
    .user-list-view .widget-author .profile-img {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .profile-img {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 991px) {
    .user-list-view .widget-author .profile-img a:before {
        display: none;
    }
}

.user-list-view .widget-author .cover-img {
    top: 50%;
    right: auto;
    left: -4%;
    -webkit-transform: rotate(-90deg) translateY(-50%);
    -ms-transform: rotate(-90deg) translateY(-50%);
    transform: rotate(-90deg) translateY(-50%);
}

@media only screen and (max-width: 991px) {
    .user-list-view .widget-author .cover-img {
        display: none;
    }
}

.user-list-view .widget-author .profile-name {
    text-align: left;
}

.user-list-view .widget-author .author-location {
    margin-bottom: 0;
}

.user-list-view .widget-author .author-badge {
    margin-bottom: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author .author-badge {
        text-align: left;
        margin-top: -40px;
        padding-left: 116px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .author-badge {
        padding-left: 105px;
    }
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author .author-badge li {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .author-badge li {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author .author-badge li a {
        text-align: center;
    }
}

.user-list-view .widget-author .author-statistics {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
}

@media only screen and (max-width: 767px) {
    .user-list-view .widget-author .author-statistics {
        text-align: left;
        padding-left: 116px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .author-statistics {
        padding-left: 113px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .author-statistics li {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .user-list-view .widget-author .author-statistics li a {
        padding: 0 10px 0 5px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .widget-author .author-statistics li a {
        padding: 0 4px 0 0;
        font-size: 12px;
    }
}

.user-list-view .user-group .member-thumb {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .user-list-view .user-group .member-thumb {
        text-align: left;
        padding-left: 133px;
        margin-top: -40px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 479px) {
    .user-list-view .user-group .author-statistics li {
        display: block;
    }

    .user-list-view .user-group .author-statistics li:after {
        display: none;
    }
}

.user-group .profile-img a:after {
    display: none;
}

.user-group .member-thumb {
    margin-bottom: 40px;
    margin-left: -20px;
    padding-left: 20px;
}

.user-group .member-thumb li {
    display: inline-block;
    margin-right: -20px;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.user-group .member-thumb li img {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.user-group .member-thumb li:last-child {
    text-align: center;
    height: 37px;
    width: 37px;
    line-height: 32px;
    background-color: #5edfff;
    color: #ffffff;
    font-size: 10px;
}

.user-group-photo {
    margin-bottom: 20px;
}

.user-group-photo a {
    border-radius: 4px;
}

.user-group-photo a img {
    border-radius: 4px;
}

.user-video {
    position: relative;
    border-radius: 4px;
    margin-bottom: 20px;
}

.user-video img {
    border-radius: 4px;
}

.user-video .video-btn {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.user-video .video-btn .play-icon {
    height: 50px;
    width: 50px;
    line-height: 46px;
    background-color: #615dfa;
    border: 2px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
}

@media only screen and (max-width: 575px) {
    .user-video .video-btn .play-icon {
        height: 40px;
        width: 40px;
        line-height: 36px;
        font-size: 14px;
    }
}

.user-video .video-btn .play-icon i {
    margin-left: 2px;
}

.user-video .video-btn .play-icon:hover {
    background-color: #5edfff;
}

.user-video:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.user-badges {
    text-align: center;
    padding: 40px 0 40px;
    overflow: hidden;
    margin-bottom: 20px;
}

.user-badges .item-badge {
    margin-bottom: 30px;
}

.user-badges .badge-icon {
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 28px;
    position: relative;
    margin: 0 auto;
    box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.25);
}

.user-badges .badge-icon:before {
    content: "";
    height: 6px;
    width: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 10px;
    -webkit-transform: rotate(28deg);
    -ms-transform: rotate(28deg);
    transform: rotate(28deg);
}

.user-badges p {
    padding: 0 30px;
    color: #9e9faf;
    font-size: 14px;
    line-height: 24px;
}

.user-badges .badge-title {
    font-weight: 800;
    margin-bottom: 8px;
}

.user-badges .badge-earn {
    margin-top: 26px;
}

.user-badges .badge-earn .item-text {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
}

.user-badges .badge-earn .member-thumb {
    margin-left: -20px;
    position: relative;
    z-index: 1;
}

.user-badges .badge-earn .member-thumb li {
    display: inline-block;
    margin-right: -20px;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.user-badges .badge-earn .member-thumb li img {
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.user-badges .badge-earn .member-thumb:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e7ebf1;
    position: absolute;
    bottom: 17px;
    left: 0;
    right: 0;
    z-index: -1;
}

.user-blog .blog-img a {
    display: block;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

.user-blog .blog-img a img {
    border-radius: 8px 8px 0 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 100%;
}

.user-blog .blog-content {
    padding: 30px 30px 15px;
}

.user-blog .blog-content .blog-category a {
    font-size: 13px;
    color: #ffffff;
    background-color: #5edfff;
    padding: 0 15px;
    border-radius: 2px;
    margin-right: 2px;
    margin-bottom: 14px;
}

.user-blog .blog-content .blog-category a:hover {
    background-color: #615dfa;
}

.user-blog .blog-content .blog-category a:last-child {
    margin-right: 0;
}

.user-blog .blog-content .blog-title {
    font-size: 22px;
    margin-bottom: 12px;
}

.user-blog .blog-content .blog-title a {
    color: #000000;
}

.user-blog .blog-content .blog-title a:hover {
    color: #615dfa;
}

.user-blog .blog-content .blog-date {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
    margin-bottom: 10px;
}

.user-blog .blog-content .blog-date i {
    font-size: 16px;
    color: #615dfa;
    margin-right: 4px;
}

.user-blog .blog-meta {
    padding: 23px 30px;
    border-top: 1px solid #e5e9ee;
}

.user-blog .blog-meta ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.user-blog .blog-meta ul li {
    color: #8c8c8c;
    font-weight: 600;
    font-size: 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.user-blog .blog-meta ul li i {
    color: #615dfa;
    font-size: 18px;
    margin-right: 8px;
}

.user-blog .blog-meta .blog-reaction img {
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-right: -10px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
}

.user-blog .blog-meta .blog-reaction img:last-child {
    margin-right: 0;
}

.user-blog .blog-meta .blog-reaction .meta-text {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #71728c;
}

.user-blog:hover .blog-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.load-more-post {
    text-align: center;
    margin-bottom: 80px;
    margin-top: 60px;
}

@media only screen and (max-width: 767px) {
    .load-more-post {
        margin-top: 40px;
        margin-bottom: 60px;
    }
}

.load-more-post .item-btn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    color: #000000;
    background-color: #ffffff;
    padding: 15px 38px;
    border-radius: 6px;
}

.load-more-post .item-btn i {
    font-size: 18px;
    margin-right: 10px;
    color: #615dfa;
    font-weight: 700;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.load-more-post .item-btn:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.load-more-post .item-btn:hover i {
    color: #ffffff;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.user-single-blog {
    margin-bottom: 30px;
}

.user-single-blog .blog-thumbnail img {
    border-radius: 8px 8px 0 0;
}

.user-single-blog .blog-content-wrap {
    padding: 40px 70px 65px;
}

@media only screen and (max-width: 1199px) {
    .user-single-blog .blog-content-wrap {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-content-wrap {
        padding: 30px 15px;
    }
}

.user-single-blog .blog-entry-header {
    border-bottom: 1px solid #e9edf1;
    padding-bottom: 20px;
    margin-bottom: 22px;
}

.user-single-blog .blog-entry-header .entry-category a {
    background-color: #5edfff;
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    padding: 0 12px;
    border-radius: 2px;
    margin-right: 2px;
    margin-bottom: 14px;
}

.user-single-blog .blog-entry-header .entry-category a:last-child {
    margin-right: 0;
}

.user-single-blog .blog-entry-header .entry-title {
    margin-bottom: 14px;
}

.user-single-blog .blog-entry-header .entry-meta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
    .user-single-blog .blog-entry-header .entry-meta {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-entry-header .entry-meta {
        margin-bottom: 10px;
    }
}

.user-single-blog .blog-entry-header .entry-meta li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 25px;
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
}

@media only screen and (max-width: 1199px) {
    .user-single-blog .blog-entry-header .entry-meta li {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-entry-header .entry-meta li {
        margin-bottom: 10px;
    }
}

.user-single-blog .blog-entry-header .entry-meta li img {
    border-radius: 50%;
    margin-right: 12px;
}

.user-single-blog .blog-entry-header .entry-meta li i {
    color: #615dfa;
    font-size: 16px;
    margin-right: 8px;
}

.user-single-blog .blog-entry-header .entry-meta li a {
    margin-left: 2px;
    color: #444444;
}

.user-single-blog .blog-entry-header .entry-meta li a:hover {
    color: #615dfa;
}

.user-single-blog .blog-entry-header .blog-share {
    text-align: right;
}

@media only screen and (max-width: 991px) {
    .user-single-blog .blog-entry-header .blog-share {
        text-align: left;
    }
}

.user-single-blog .blog-entry-header .blog-share li {
    display: inline-block;
    margin-right: 2px;
}

.user-single-blog .blog-entry-header .blog-share li a {
    text-align: center;
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    font-size: 18px;
    color: #ffffff;
}

.user-single-blog .blog-content {
    margin-bottom: 55px;
}

.user-single-blog .blog-content blockquote {
    position: relative;
    margin: 34px 30px;
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-content blockquote {
        margin: 20px 15px;
    }
}

.user-single-blog .blog-content blockquote:before {
    content: "";
    height: 100%;
    width: 4px;
    background-color: #5edfff;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.user-single-blog .blog-content blockquote p {
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    color: #444444;
    padding-left: 20px;
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-content blockquote p {
        font-size: 16px;
    }
}

.user-single-blog .blog-content .content-img {
    margin-bottom: 5px;
    margin-top: 25px;
}

.user-single-blog .blog-content .content-img img {
    border-radius: 8px;
}

.user-single-blog .blog-content .item-title {
    font-size: 30px;
    margin-top: 35px;
}

@media only screen and (max-width: 991px) {
    .user-single-blog .blog-content .item-title {
        font-size: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-content .item-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 575px) {
    .user-single-blog .blog-content .item-title {
        font-size: 24px;
    }
}

.user-single-blog .blog-footer {
    margin-bottom: 60px;
    text-align: center;
    border-top: 1px solid #e9edf1;
    border-bottom: 1px solid #e9edf1;
    padding: 28px 0 35px;
}

.user-single-blog .blog-footer .item-label {
    font-size: 14px;
    color: #71728c;
    font-weight: 600;
    margin-bottom: 4px;
}

.user-single-blog .blog-footer .item-label span {
    color: #000000;
}

.user-single-blog .blog-footer .reaction-icon img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
}

.user-single-blog .blog-comment-form .item-title {
    font-size: 30px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
    .user-single-blog .blog-comment-form .item-title {
        font-size: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .user-single-blog .blog-comment-form .item-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 575px) {
    .user-single-blog .blog-comment-form .item-title {
        font-size: 24px;
    }
}

.user-single-blog .blog-comment-form .form-control {
    border-color: #d7d7d7;
}

.user-single-blog .blog-comment-form .submit-btn {
    font-size: 16px;
    padding: 11px 32px;
}

.user-single-blog .blog-comment-form input.form-control::-webkit-input-placeholder,
  .user-single-blog .blog-comment-form textarea.form-control::-webkit-input-placeholder {
    color: #9d9d9d;
}

.realated-blog .blog-heading {
    margin-bottom: 30px;
    padding: 16px 25px;
}

.realated-blog .blog-heading .heading-title {
    font-size: 16px;
    margin-bottom: 0;
}

/*------------------- 6.8 Widget -------------------*/
.widget {
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 25px 30px 35px;
}

@media only screen and (max-width: 1199px) {
    .widget {
        padding: 25px 25px 35px;
    }
}

@media only screen and (max-width: 575px) {
    .widget {
        padding: 25px 20px 35px;
    }
}

.widget:last-child {
    margin-bottom: 0 !important;
}

.widget .see-all-btn {
    padding: 35px 0 0;
}

.widget .see-all-btn .item-btn {
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 3px;
    background-color: #5edfff;
    padding: 10px 20px;
}

.widget .see-all-btn .item-btn:hover {
    background-color: #615dfa;
}

@media only screen and (max-width: 991px) {
    .widget-break-lg {
        margin-top: 50px;
    }
}

.widget-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 14px;
}

.widget-heading .widget-title {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    position: relative;
    padding-bottom: 15px;
}

.widget-heading .widget-title:before {
    content: "";
    height: 3px;
    width: 8px;
    background-color: #615dfa;
    border-radius: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.widget-heading .widget-title:after {
    content: "";
    height: 3px;
    width: 18px;
    background-color: #615dfa;
    border-radius: 4px;
    position: absolute;
    left: 10px;
    bottom: 0;
}

.widget-heading .dropdown {
    line-height: 1;
}

.widget-heading .dropdown.show .dropdown-toggle {
    color: #ffffff;
}

.widget-heading .dropdown.show .dropdown-toggle:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.widget-heading .dropdown-toggle {
    background-color: transparent;
    border: none;
    font-size: 34px;
    color: #bbc5d3;
    line-height: 1;
    margin-top: -16px;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-heading .dropdown-toggle:before {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #5edfff;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: -2px;
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-heading .dropdown-toggle:after {
    display: none;
}

.widget-heading .dropdown-toggle:hover {
    color: #ffffff;
}

.widget-heading .dropdown-toggle:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.widget-heading .dropdown-menu {
    min-width: 130px;
    border: none;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
    margin-top: 15px;
}

.widget-heading .dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 10px 20px;
}

.widget-heading .dropdown-menu .dropdown-item:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.widget-author {
    text-align: center;
    padding: 40px 15px 30px;
    position: relative;
}

.widget-author .cover-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.widget-author .cover-img img {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100px;
}

.widget-author .profile-img {
    margin-bottom: 26px;
}

.widget-author .profile-img a {
    border-radius: 50%;
    position: relative;
}

.widget-author .profile-img a img {
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    padding: 5px;
    border-radius: 50%;
}

.widget-author .profile-img a:before {
    content: url("../../media/figure/chat_round_shape5.png");
    position: absolute;
    top: -8px;
    left: -8px;
}

.widget-author .profile-img a:after {
    content: "\eed8";
    font-family: IcoFont;
    font-size: 20px;
    color: #ffffff;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: #17dd17;
    border-radius: 50%;
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.widget-author .author-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
}

.widget-author .author-name a {
    color: #000000;
}

.widget-author .author-name a:hover {
    color: #615dfa;
}

.widget-author .author-location {
    font-size: 14px;
    color: #9e9faf;
    margin-bottom: 24px;
}

.widget-author .author-badge {
    margin-bottom: 45px;
}

.widget-author .author-badge li {
    display: inline-block;
    margin-right: 8px;
}

.widget-author .author-badge li:last-child {
    margin-right: 0;
}

.widget-author .author-badge a {
    font-size: 20px;
    color: #ffffff;
    height: 44px;
    width: 44px;
    line-height: 44px;
    border-radius: 50%;
    position: relative;
}

.widget-author .author-badge a:before {
    content: "";
    height: 5px;
    width: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: absolute;
    top: 4px;
    right: 8px;
    -webkit-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
}

.widget-author .author-badge .profile-circle {
    background-color: #e7ecf4;
    font-size: 16px;
    font-weight: 700;
    color: #8f8f8f;
}

.widget-author .author-statistics li {
    display: inline-block;
    position: relative;
    line-height: 1;
}

.widget-author .author-statistics li:after {
    content: "";
    height: 31px;
    width: 1px;
    background-color: #e8e8e8;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.widget-author .author-statistics li:last-child {
    margin-right: 0;
}

.widget-author .author-statistics li:last-child:after {
    display: none;
}

.widget-author .author-statistics li a {
    text-align: center;
    padding: 0 15px 0 12px;
}

.widget-author .author-statistics .item-number {
    font-weight: 700;
    color: #000000;
    display: block;
    margin-bottom: 5px;
}

.widget-author .author-statistics .item-text {
    font-weight: 600;
    color: #9e9faf;
    font-size: 12px;
}

.widget-memebers .nav-tabs {
    border-bottom: none;
    margin-bottom: 26px;
}

.widget-memebers .nav-tabs .nav-item {
    margin-right: 10px;
    margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
    .widget-memebers .nav-tabs .nav-item {
        margin-right: 5px;
    }
}

.widget-memebers .nav-tabs .nav-item:last-child {
    margin-right: 0;
}

.widget-memebers .nav-tabs .nav-link {
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-weight: 700;
    background-color: transparent;
    color: #646464;
    border-radius: 4px;
    padding: 0 10px;
}

.widget-memebers .nav-tabs .nav-link:hover {
    border-color: #5edfff;
    background-color: #5edfff;
    color: #ffffff;
}

.widget-memebers .nav-tabs .nav-link.active {
    border-color: #5edfff;
    background-color: #5edfff;
    color: #ffffff;
}

.widget-memebers .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 32px;
}

.widget-memebers .media:last-child {
    margin-bottom: 0;
}

.widget-memebers .media .item-img {
    margin-right: 18px;
    position: relative;
}

.widget-memebers .media .item-img a img {
    border-radius: 50%;
}

.widget-memebers .media .item-img a:before {
    content: url("../../media/figure/chat_round_shape.png");
    position: absolute;
    top: -5px;
    left: -5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-memebers .media .item-img a:after {
    content: url("../../media/figure/chat_round_shape2.png");
    position: absolute;
    top: -5px;
    left: -5px;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-memebers .media .media-body {
    position: relative;
}

.widget-memebers .media .item-title {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1;
}

.widget-memebers .media .item-title a {
    color: #000000;
}

.widget-memebers .media .item-title a:hover {
    color: #615dfa;
}

.widget-memebers .media .item-username {
    font-size: 12px;
    color: #9e9faf;
    line-height: 1;
}

.widget-memebers .media .member-status {
    text-align: center;
    position: absolute;
    top: 3px;
    right: 0;
    height: 21px;
    width: 21px;
    line-height: 21px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 17px;
}

.widget-memebers .media .member-status.online {
    background-color: #17dd17;
}

.widget-memebers .media .member-status.offline {
    background-color: #dde2e9;
}

.widget-memebers .media:hover .item-img a:before {
    visibility: hidden;
    opacity: 0;
}

.widget-memebers .media:hover .item-img a:after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    visibility: visible;
    opacity: 1;
}

.widget-groups .nav-tabs {
    border-bottom: none;
    margin-bottom: 25px;
}

.widget-groups .nav-tabs .nav-item {
    margin-right: 10px;
    margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
    .widget-groups .nav-tabs .nav-item {
        margin-right: 5px;
    }
}

.widget-groups .nav-tabs .nav-item:last-child {
    margin-right: 0;
}

.widget-groups .nav-tabs .nav-link {
    border: 1px solid #e5e5e5;
    font-size: 12px;
    font-weight: 700;
    background-color: transparent;
    color: #646464;
    border-radius: 4px;
    padding: 0 10px;
}

.widget-groups .nav-tabs .nav-link:hover {
    border-color: #5edfff;
    background-color: #5edfff;
    color: #ffffff;
}

.widget-groups .nav-tabs .nav-link.active {
    border-color: #5edfff;
    background-color: #5edfff;
    color: #ffffff;
}

.widget-groups .media {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
}

.widget-groups .media:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.widget-groups .media .item-img {
    margin-right: 14px;
}

.widget-groups .media .item-img img {
    border-radius: 50%;
}

.widget-groups .media .media-body {
    position: relative;
}

.widget-groups .media .item-title {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1;
}

.widget-groups .media .item-title a {
    color: #000000;
}

.widget-groups .media .item-title a:hover {
    color: #615dfa;
}

.widget-groups .media .item-member {
    font-size: 12px;
    color: #9e9faf;
    line-height: 1;
}

.widget-activity .media {
    margin-bottom: 25px;
}

.widget-activity .media .activity-time {
    font-size: 12px;
    color: #9e9faf;
    line-height: 1;
    font-weight: 600;
}

.widget-activity .media .item-title {
    font-weight: 400;
    color: #71728c;
    line-height: 18px;
    width: 80%;
    margin-bottom: 8px;
}

.widget-activity .media .item-title .author-name {
    font-weight: 700;
}

.widget-activity .media .item-title .activity-link {
    font-weight: 600;
    color: #5edfff;
}

.widget-banner {
    background-color: #ffca28;
    padding: 30px 0 0;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.widget-banner:after {
    /*content: url("../../media/figure/square_shape.png");
    position: relative;
    top: 0;
    left: 0;
    pointer-events: none;*/
}

@media only screen and (max-width: 991px) {
    .widget-banner:after {
        display: none;
    }
}

.widget-banner .item-title {
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 4px;
}

.widget-banner .item-subtitle {
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 22px;
}

.widget-banner .item-btn {
    margin-bottom: 25px;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 6px 22px;
    box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.1);
}

.widget-banner .item-btn .btn-icon {
    margin-left: 5px;
}

.widget-banner .item-btn svg path {
    fill: #615dfa;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-banner .item-btn:hover {
    background-color: #5edfff;
    color: #ffffff;
}

.widget-banner .item-btn:hover svg path {
    fill: #ffffff;
}

.widget-banner .item-img img {
    border-radius: 0 0 8px 8px;
}

.widget-user-about .user-info p {
    color: #9e9faf;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
}

.widget-user-about .info-list li {
    font-size: 14px;
    margin-bottom: 6px;
}

.widget-user-about .info-list li:last-child {
    margin-bottom: 0;
}

.widget-user-about .info-list li span {
    color: #9e9faf;
    min-width: 75px;
    display: inline-block;
}

.widget-user-about .info-list li a {
    color: #5edfff;
}

.widget-user-about .info-list .social-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.widget-user-about .info-list .social-share .social-icon a {
    color: #646464;
    font-size: 15px;
    padding: 0 5px;
}

.widget-user-about .info-list .social-share .social-icon a:hover {
    color: #615dfa;
}

.widget-user-about .info-list .social-share .social-icon a:first-child {
    padding-left: 0;
}

.widget-gallery {
    padding-bottom: 28px;
}

.widget-gallery .photo-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -2.5px;
    margin-right: -2.5px;
}

.widget-gallery .photo-list li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative;
    width: 100%;
    padding-left: 2.5px;
    padding-right: 2.5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 991px) {
    .widget-gallery .photo-list li {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media only screen and (max-width: 479px) {
    .widget-gallery .photo-list li {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.widget-gallery .photo-list li:last-child a:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(97, 93, 250, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.widget-gallery .photo-list li:last-child a:after {
    content: attr(data-photo);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    color: #ffffff;
    font-weight: 700;
}

.widget-gallery .photo-list li a {
    overflow: hidden;
    border-radius: 4px;
    display: block;
    position: relative;
    text-align: center;
}

.widget-gallery .photo-list li a img {
    border-radius: 4px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.widget-gallery .photo-list li a:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.widget-badges {
    padding-bottom: 20px;
}

.widget-badges .badge-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.widget-badges .badge-list li {
    display: inline-block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 15px;
}

.widget-badges .badge-list li a {
    text-align: center;
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 20px;
    position: relative;
}

.widget-badges .badge-list li a:before {
    content: "";
    height: 5px;
    width: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: absolute;
    top: 4px;
    right: 8px;
    -webkit-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
}

.widget-comment .media {
    margin-bottom: 10px;
}

.widget-comment .media:last-child {
    margin-bottom: 0;
}

.widget-comment .media .item-img {
    margin-right: 18px;
}

.widget-comment .media .item-img a {
    overflow: hidden;
    border-radius: 4px;
}

.widget-comment .media .item-img img {
    border-radius: 4px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-comment .media .item-title {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 20px;
}

.widget-comment .media .item-title a {
    color: #000000;
}

.widget-comment .media .item-title a:hover {
    color: #615dfa;
}

.widget-comment .media .post-date {
    font-size: 12px;
    font-weight: 600;
    color: #9e9faf;
    margin-bottom: 2px;
}

.widget-comment .media:hover .item-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
