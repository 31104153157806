.loginPageStyle{
  height: 100%;
  /* background-image: url(/static/media/hero_1.abbb7fcf.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  padding-left: 52px;
  font-family: "Nunito", sans-serif;

}

.advertBanner{
    background-color: #f6a43f;
    /* padding: 30px 0 0; */
    text-align: center;
    position: relative;
    overflow: hidden;


  }

  @media (min-width: 1200px){
    .hidden-menu {
      display: none;
    }
  }